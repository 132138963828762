import React, { useCallback, useState } from "react"
import { Drawer } from "antd"
import { DeviceForm } from "~/components"

const useDevice = ({
  device,
  loading = false,
  width = "60%",
  onClose,
  onSubmit,
  onRemove,
} = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const DeviceDrawer = useCallback(() => {
    return (
      <Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Device"}
        open={visible}
        onClose={onCancel}
      >
        <DeviceForm
          id={device?.id}
          initialValues={device}
          onSubmit={onSubmit}
          onConfirmDelete={onRemove}
          onCancel={onCancel}
          loading={loading}
        />
      </Drawer>
    )
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    DeviceDrawer,
  }
}

export default useDevice
