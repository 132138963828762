import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons"
import { Image, List } from "antd"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { getSupply } from "~/services/apis"

function SupplyDetail() {
  const history = useHistory()
  const { supplyId } = useParams()
  const [loading, setLoading] = useState(false)
  const [supply, setSupply] = useState(null)

  const fetchSupply = async () => {
    try {
      setLoading(true)
      const response = await getSupply(supplyId)

      if (response?.supply) {
        setSupply(response.supply)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchSupply()
  }, [])

  return (
    <div className="relative mx-auto min-h-screen w-full max-w-lg bg-gray-100 shadow-lg">
      {loading && (
        <div className="absolute z-[20] grid h-full w-full place-items-center bg-black/60">
          <LoadingOutlined className="text-[48px] text-primary-color" />
        </div>
      )}
      <div className="relative z-[10]">
        <div className="sticky left-0 right-0 top-0 z-40 flex h-16 flex-row items-center justify-between bg-[#3a2b2a] pl-5 pr-10 shadow">
          <ArrowLeftOutlined
            className="cursor-pointer text-[20px] text-white"
            onClick={() => {
              if (window.history.length > 1) {
                history.goBack()
              } else {
                window.location.href = "https://demeter.vn"
              }
            }}
          />
          <h1 className="text-[16px] font-medium text-white">Vật tư nông nghiệp</h1>
        </div>

        <div className="pb-16">
          <div className="p-4">
            <div className="rounded-lg bg-white p-4 shadow">
              <h2 className="mt-0">{supply?.name}</h2>

              <List
                dataSource={[
                  {
                    label: "Nhà sản xuất",
                    value: supply?.producerName,
                  },
                  {
                    label: "Thương hiệu",
                    value: supply?.brandName,
                  },
                  {
                    label: "Link mua vật tư",
                    value: supply?.url,
                    url: true,
                  },
                  {
                    label: "Phân loại",
                    value: supply?.kind,
                  },
                ]}
                renderItem={(item) => (
                  <List.Item key={item.label}>
                    <List.Item.Meta title={item.label} />
                    {item?.url ? (
                      <a href={item.url}>{item?.value}</a>
                    ) : (
                      <div className="line-clamp-1 max-w-[200px]">
                        {item.value || "Đang cập nhật"}
                      </div>
                    )}
                  </List.Item>
                )}
              />

              <h2 className="mb-0 mt-[16px]">Hình ảnh</h2>
              <div className="-mx-2 mt-[10px] flex flex-wrap gap-2 pl-[32px] [&_.ant-image-mask]:rounded">
                <Image.PreviewGroup>
                  {supply?.imageUrls?.map((image) => (
                    <Image
                      key={image}
                      width={90}
                      height={90}
                      className="rounded object-contain"
                      src={image}
                    />
                  ))}
                </Image.PreviewGroup>

                {supply?.imageUrls?.length === 0 && <div>Đang cập nhật</div>}
              </div>

              <div className="mt-[32px]">
                <h2 className="mb-1">Đặc tính</h2>
                <div className="">{supply?.features || "Đang cập nhật"}</div>
              </div>

              <div className="mt-[32px]">
                <h2 className="mb-1">Mô tả</h2>
                <div
                  className="ck-content relative"
                  dangerouslySetInnerHTML={{ __html: supply?.description || "Đang cập nhật" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupplyDetail
