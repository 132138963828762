import React, { Suspense, useEffect } from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { AuthProvider } from "./contexts/auth"
import { AppLoading } from "~/components"
import DefaultLayout from "./components/DefaultLayout"
import { whoAmI } from "./services/apis"
import Cookies from "js-cookie"
import ScrollToTop from "./components/ScrollToTop"
import { ConfigProvider, App as AntdApp } from "antd"
import vi_VN from "antd/locale/vi_VN"
import { ProjectProvider } from "./contexts/project"
import { PageConfigProvider } from "./contexts/page-title"
import TraceabilityClientPage from "./pages/traceabilities/client"
import EmployeePage from "./pages/employee"
import EmployeeTasks from "./pages/employee/task"
import EmployeeTaskDetail from "./pages/employee/task"
import SupplyDetail from "./pages/traceabilities/supply"
import ProductDetail from "./pages/traceabilities/product"

const App = () => {
  const token = Cookies.get("token")

  useEffect(() => {
    token && whoAmI()
  }, [token])

  const theme = {
    token: {
      colorPrimary: "rgb(0, 147, 59)",
      colorSecondary: "white",
      colorLink: "rgb(0, 147, 59)",
      colorInfo: "rgb(0, 147, 59)",
    },
    components: {
      Menu: {
        itemHeight: 50,
      },
    },
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<AppLoading />}>
        <ConfigProvider locale={vi_VN} theme={theme}>
          <AntdApp>
            <ScrollToTop>
              <Switch>
                <Route
                  path="/traceability/client/:traceabilityId"
                  render={(props) => <TraceabilityClientPage {...props} />}
                />
                <Route
                  path="/traceability/supply/:supplyId"
                  render={(props) => <SupplyDetail {...props} />}
                />
                <Route
                  path="/traceability/product/:productId"
                  render={(props) => <ProductDetail {...props} />}
                />
                <Route
                  path="/employee/dashboard"
                  render={(props) => (
                    <AuthProvider>
                      <EmployeePage {...props} />
                    </AuthProvider>
                  )}
                />
                <Route
                  path="/employee/tasks/:stageTaskId"
                  render={(props) => (
                    <AuthProvider>
                      <EmployeeTaskDetail {...props} />
                    </AuthProvider>
                  )}
                />
                <Route path="/employee/tasks" render={(props) => <EmployeeTasks {...props} />} />
                <Route
                  path="/"
                  render={(props) => (
                    <AuthProvider>
                      <ProjectProvider>
                        <PageConfigProvider>
                          <DefaultLayout {...props} />
                        </PageConfigProvider>
                      </ProjectProvider>
                    </AuthProvider>
                  )}
                />
              </Switch>
            </ScrollToTop>
          </AntdApp>
        </ConfigProvider>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
