import React, { useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button, Popconfirm, Select, InputNumber, Tag } from "antd"
import { isEmpty, isEqual } from "lodash"
import { DatePicker, ProcedureSelect, SupplySelect, Upload, UserSelect } from ".."
import { useAuth } from "~/hooks"
import { last } from "lodash"
import StageSelect from "../StageSelect"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import numeral from "numeral"

const TaskForm = ({
  id,
  loading,
  initialValues,
  readOnly,
  quickTask = false,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const { user } = useAuth()
  const [form] = Form.useForm()
  const watchStageTaskSupplies = Form.useWatch("stageTaskSupplies", form)

  const handleOnSupplySelect = (key, supply) => {
    console.log(key, supply)
    if (supply?.supplyStockCards?.length > 0) {
      form.setFieldValue(["stageTaskSupplies", key, "price"], supply?.supplyStockCards[0]?.price)
    }
  }

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = (values) => {
    const { stageTaskSupplies, assignedToId, ...rest } = values

    onSubmit({
      ...rest,
      ...(stageTaskSupplies?.length > 0 && {
        stageTaskSupplies: stageTaskSupplies.map((tS) => ({
          supplyId: tS.supply?.value,
          quantity: tS.quantity,
          price: numeral(tS.price).value(),
        })),
      }),
      assignedToId: assignedToId?.value,
    })
  }

  return (
    <div>
      <Form
        colon={false}
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
        disabled={readOnly}
      >
        <Row gutter={20}>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Tên công việc"}
              name="name"
              rules={[{ required: true, message: "Tên công việc là bắt buộc!" }]}
            >
              <Input placeholder={"Nhập tên công việc"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              label={"Mức độ ưu tiên"}
              name="priority"
              rules={[{ required: true, message: "Mức độ ưu tiên là bắt buộc" }]}
            >
              <Select
                options={[
                  {
                    label: <Tag color="blue">Thông thường</Tag>,
                    value: "common",
                  },
                  {
                    label: <Tag color="gold">Ưu tiên</Tag>,
                    value: "high",
                  },
                  {
                    label: <Tag color="red">Quan trọng</Tag>,
                    value: "important",
                  },
                ]}
                placeholder={"Chọn mức độ ưu tiên"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item label={"Gán người thực hiện"} name="assignedToId">
              <UserSelect />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Mô tả thêm"} name="description">
              <Input.TextArea placeholder={"Nhập mô tả"} rows={3} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Hình ảnh mô tả"} name="imageUrls">
              <Upload multiple />
            </Form.Item>
          </Col>

          <Divider />

          <Col xs={24} lg={24}>
            <Form.Item label={<h3>Vật tư sử dụng</h3>}>
              <div>
                <Form.List name="stageTaskSupplies">
                  {(fields, { add, remove }) => (
                    <div className={""}>
                      {fields.map(({ key, name, ...restFields }, index) => (
                        <Row key={key} gutter={[16, 8]} className="mb-[16px] w-full">
                          {index > 0 && (
                            <Col span={24}>
                              <Divider />
                            </Col>
                          )}
                          <Col xs={24} lg={12} xl={8}>
                            <Form.Item
                              {...restFields}
                              label={`Sản phẩm vật tư ${name + 1}`}
                              name={[name, "supply"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng chọn sản phẩm vật tư",
                                },
                              ]}
                            >
                              <SupplySelect
                                allowClear={false}
                                onChange={(_, supply) => handleOnSupplySelect(name, supply)}
                              />
                            </Form.Item>

                            <Form.Item
                              {...restFields}
                              label={`Đơn giá (tại thời điểm nhập hàng cuối cùng)`}
                              name={[name, "price"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng nhập đơn giá",
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                formatter={(value) =>
                                  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                                className="w-full"
                                placeholder="Đơn giá"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12} xl={8}>
                            <Form.Item
                              {...restFields}
                              label={`Số lượng sử dụng`}
                              name={[name, "quantity"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng nhập số lượng sử dụng",
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                formatter={(value) =>
                                  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                                className="w-full"
                                placeholder="Liều lượng sử dụng"
                              />
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate>
                              {({ getFieldValue }) => (
                                <Form.Item label={"Tổng tiền tạm tính"}>
                                  <b>
                                    {(
                                      (getFieldValue(["stageTaskSupplies", name, "price"]) || 0) *
                                      (getFieldValue(["stageTaskSupplies", name, "quantity"]) || 0)
                                    )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                    đ
                                  </b>
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>
                          <Col xs={23} xl={7}>
                            <Form.Item {...restFields} label={`Ghi chú`} name={[name, "note"]}>
                              <Input.TextArea placeholder="Ghi chú" rows={5} />
                            </Form.Item>
                          </Col>
                          <Col span={1} className="flex items-center">
                            <MinusCircleOutlined
                              className="cursor-pointer"
                              onClick={() => remove(name)}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Button
                        onClick={() => {
                          if (
                            last(watchStageTaskSupplies)?.supply ||
                            !watchStageTaskSupplies ||
                            watchStageTaskSupplies?.length === 0
                          ) {
                            add()
                          }
                        }}
                        icon={<PlusOutlined />}
                        type="primary"
                        style={{ width: "100%" }}
                      >
                        Thêm vật tư nông nghiệp
                      </Button>
                    </div>
                  )}
                </Form.List>
              </div>
            </Form.Item>
          </Col>

          {quickTask ? (
            <Col xs={24} lg={24}>
              <Divider />
              <Form.Item label={<h3>Thời gian thực hiện</h3>}>
                <Form.List name="times" initialValue={[{}]}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key}>
                          {fields.length > 1 && (
                            <div className="mb-2 flex justify-end">
                              <MinusCircleOutlined onClick={() => remove(name)} />
                            </div>
                          )}

                          <Row gutter={20}>
                            <Col xs={24} lg={24}>
                              <Form.Item
                                {...restField}
                                label="Chọn quy trình"
                                name={[name, "procedureId"]}
                                rules={[{ required: true, message: "Quy trình là bắt buộc!" }]}
                              >
                                <ProcedureSelect
                                  allowClear={false}
                                  onChange={() => {
                                    form.setFieldValue(["times", name, "stageId"], undefined)
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} lg={24}>
                              <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => (
                                  <Form.Item
                                    {...restField}
                                    label="Chọn giai đoạn"
                                    name={[name, "stageId"]}
                                    rules={[{ required: true, message: "Giai đoạn là bắt buộc!" }]}
                                  >
                                    <StageSelect
                                      disabled={!getFieldValue(["times", name, "procedureId"])}
                                      procedureId={
                                        getFieldValue(["times", name, "procedureId"])?.value
                                      }
                                      allowClear={false}
                                    />
                                  </Form.Item>
                                )}
                              </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                              <Form.Item
                                {...restField}
                                label="Ngày bắt đầu"
                                name={[name, "startedAt"]}
                                rules={[{ required: true, message: "Ngày bắt đầu là bắt buộc" }]}
                              >
                                <DatePicker
                                  inputReadonly
                                  showTime
                                  showSecond={false}
                                  className="w-full"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                              <Form.Item
                                {...restField}
                                label="Ngày kết thúc"
                                name={[name, "endedAt"]}
                                rules={[{ required: true, message: "Ngày kết thúc là bắt buộc" }]}
                              >
                                <DatePicker showTime showSecond={false} className="w-full" />
                              </Form.Item>
                            </Col>
                          </Row>
                          {fields.length > 1 && <Divider />}
                        </div>
                      ))}

                      <Button
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        className="mt-4 w-full"
                        type="primary"
                      >
                        Thêm thời gian thực hiện
                      </Button>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          ) : (
            <Col xs={24} lg={24}>
              <Divider />
              <Form.Item label={<h3>Thời gian thực hiện</h3>}>
                <Row gutter={20}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label="Ngày bắt đầu"
                      name={"startedAt"}
                      rules={[{ required: true, message: "Ngày bắt đầu là bắt buộc" }]}
                    >
                      <DatePicker inputReadonly showTime showSecond={false} className="w-full" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      label="Ngày kết thúc"
                      name={"endedAt"}
                      rules={[{ required: true, message: "Ngày kết thúc là bắt buộc" }]}
                    >
                      <DatePicker showTime showSecond={false} className="w-full" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          )}
        </Row>
        {!readOnly && (
          <>
            <Divider />
            <Row style={{ alignItems: "center" }} justify="space-between">
              <div className="flex w-full justify-end">
                <Button loading={loading} type={"primary"} htmlType={"submit"}>
                  Lưu
                </Button>
                <Button
                  onClick={onCancel}
                  disabled={loading}
                  type={"outline"}
                  style={{ marginLeft: 16 }}
                >
                  Hủy
                </Button>
              </div>
              {id && user?.role !== "employee" && (
                <div>
                  <Popconfirm
                    title={"Bạn có chắc chắn muốn xóa công việc này ?"}
                    onConfirm={onRemove}
                  >
                    <Button loading={loading} danger style={{ marginLeft: 16 }}>
                      Xóa công việc
                    </Button>
                  </Popconfirm>
                </div>
              )}
            </Row>
          </>
        )}
      </Form>
    </div>
  )
}

export default TaskForm
