import React, { useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button, Popconfirm, InputNumber, Select, Tag } from "antd"
import { isEmpty, isEqual } from "lodash"
import { Upload } from ".."
import CustomEditor from "../Editor"
import { EMAIL_REGEX, PHONE_REGEX, URL_REGEX } from "~/constants"
import { useAuth } from "~/hooks"

const EnterpriseForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm()
  const { user } = useAuth()

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = (values) => {
    const { ...rest } = values

    onSubmit({
      ...rest,
    })
  }

  return (
    <div className="max-lg:p-3">
      <Form
        colon={false}
        form={form}
        layout={"vertical"}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Ảnh đại diện"}
              name="thumbnailUrl"
              rules={[{ required: true, message: "Hình ảnh là bắt buộc!" }]}
            >
              <Upload />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label={"Hình ảnh thêm"} name="imageUrls">
              <Upload multiple />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={"Mã doanh nghiệp"}
              name="businessCode"
              rules={[{ required: true, message: "Mã doanh nghiệp là bắt buộc!" }]}
            >
              <Input placeholder={"Nhập mã doanh nghiệp"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Tên doanh nghiệp"}
              name="businessName"
              rules={[{ required: true, message: "Tên doanh nghiệp là bắt buộc!" }]}
            >
              <Input placeholder={"Nhập tên doanh nghiệp"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Số điện thoại"}
              name="businessPhone"
              rules={[
                { required: true, message: "Số điện thoại là bắt buộc!" },
                {
                  pattern: PHONE_REGEX,
                  message: "SĐT không hợp lệ!",
                },
              ]}
            >
              <Input placeholder={"Nhập số điện thoại"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Email"}
              name="businessEmail"
              rules={[
                { required: true, message: "Email là bắt buộc!" },
                {
                  pattern: EMAIL_REGEX,
                  message: "Email không hợp lệ!",
                },
              ]}
            >
              <Input placeholder={"Nhập email"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Địa chỉ"}
              name="businessAddress"
              rules={[{ required: true, message: "Địa chỉ là bắt buộc!" }]}
            >
              <Input placeholder={"Nhập địa chỉ"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Liên kết Website"}
              name="businessWebsite"
              rules={[
                {
                  pattern: URL_REGEX,
                  message: "Định dạng liên kết không hợp lệ!",
                },
              ]}
            >
              <Input placeholder={"Nhập vào liên kết website (url)"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Giới thiệu"} name="description">
              <CustomEditor />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <div className="text-end">
          <Button size={"large"} loading={loading} type={"primary"} htmlType={"submit"}>
            Lưu
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default EnterpriseForm
