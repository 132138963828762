import React, { createContext, useState } from "react"

export const PageConfigContext = createContext({})

export const PageConfigProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState(null)

  return (
    <PageConfigContext.Provider
      value={{
        pageTitle,
        setPageTitle,
      }}
    >
      {children}
    </PageConfigContext.Provider>
  )
}
