import { PlusOutlined } from "@ant-design/icons"
import { Avatar, Button, Divider, Image, Modal } from "antd"
import dayjs from "dayjs"
import vi from "dayjs/locale/vi"
import relativeTime from "dayjs/plugin/relativeTime"
import React, { useState } from "react"
import CommentForm from "~/components/CommentForm"
import { getAvatarBackgroundColor } from "~/utils"
import { createComment } from "~/services/apis"

dayjs.extend(relativeTime)
dayjs.locale(vi)

function CommentList({ stageTaskId, comments = [], onFinish }) {
  const [commentModalOpen, setCommentModalOpen] = useState(false)
  const [commenting, setCommenting] = useState(false)

  const onCreateComment = async (values) => {
    try {
      setCommenting(true)

      const result = await createComment({
        ...values,
        stageTaskId,
      })

      if (result?.createComment) {
        onFinish?.()
        setCommentModalOpen(false)
        setCommenting(false)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setCommenting(false)
    }
  }

  return (
    <div>
      <div>
        <Button
          size="small"
          className="m-0 p-0"
          type={"link"}
          onClick={() => setCommentModalOpen(true)}
        >
          <PlusOutlined /> Thêm thảo luận
        </Button>

        <Modal footer={null} open={commentModalOpen} onCancel={() => setCommentModalOpen(false)}>
          <CommentForm
            initialValues={{
              content: "",
              imageUrls: [],
            }}
            onCancel={() => setCommentModalOpen(false)}
            loading={commenting}
            onSubmit={onCreateComment}
          />
        </Modal>
      </div>

      {comments?.length === 0 && (
        <div className="grid h-[100px] place-items-center">Không có thảo luận</div>
      )}

      <div className="mt-[32px]">
        {comments?.length > 0 &&
          comments?.map((comment, index) => (
            <div key={comment?.id}>
              {index > 0 && <Divider />}
              <div className="flex w-full justify-between">
                <div className="flex items-center gap-[6px]">
                  <Avatar
                    style={{
                      backgroundColor: getAvatarBackgroundColor(comment?.creator?.firstName?.[0]),
                    }}
                    size={"small"}
                    className="mr-[4px]"
                  >
                    {comment?.creator?.firstName?.[0] || ""}
                  </Avatar>
                  <div className="line-clamp-1 font-semibold">{`${comment?.creator?.firstName} ${comment?.creator?.lastName}`}</div>
                </div>
                <div>{dayjs(comment?.createdAt).fromNow()}</div>
              </div>
              <div className="mt-[8px] pl-[32px]">{comment?.content}</div>
              <div className="-mx-2 mt-[10px] flex flex-wrap gap-2 pl-[32px] [&_.ant-image-mask]:rounded [&_.ant-image]:mr-[10px]">
                {comment?.imageUrls?.length > 0 && (
                  <Image.PreviewGroup>
                    {comment?.imageUrls?.map((image) => (
                      <Image
                        key={image}
                        width={90}
                        height={90}
                        className="rounded object-cover"
                        src={image}
                      />
                    ))}
                  </Image.PreviewGroup>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default CommentList
