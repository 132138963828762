import React, { useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button } from "antd"
import { DatePicker, Upload } from "~/components"
import { isEmpty, isEqual } from "lodash"

const ProcedureStageForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = (values) => {
    const { procedure, stage, ...rest } = values

    onSubmit({
      ...rest,
      name: values?.stage?.name,
    })
  }

  return (
    <div>
      <Form
        layout={"vertical"}
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24}>
            <Form.Item
              label={"Tên giai đoạn"}
              name={["stage", "name"]}
              rules={[{ required: true, message: "Tên giai đoạn là bắt buộc" }]}
            >
              <Input placeholder={"Nhập tên giai đoạn"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Ngày bắt đầu giai đoạn"} name="startDate">
              <DatePicker inputReadonly className="w-full" />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Ngày hoàn thành giai đoạn"} name="completedAt">
              <DatePicker inputReadonly className="w-full" />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Ghi chú"} name="note">
              <Input.TextArea placeholder={"Nhập ghi chú"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Hình ảnh thêm"} name="imageUrls">
              <Upload multiple />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              Lưu
            </Button>
            <Button
              onClick={onCancel}
              disabled={loading}
              type={"outline"}
              style={{ marginLeft: 16 }}
            >
              Hủy
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  )
}

export default ProcedureStageForm
