import React, { useState } from "react"
import { Switch, Redirect } from "react-router-dom"
import { Layout, Row, Col, Tooltip } from "antd"
import { Link } from "react-router-dom"
import PrivateRoute from "../PrivateRoute"
import { routes } from "../../_routes"
import { Svg } from "~/components"
import { useAuth } from "~/hooks"
import CustomSider from "./CustomSider"
import LoginHeader from "../LoginHeader"
import LoginFooter from "../LoginFooter"

import "./styles.less"
import usePageConfigContext from "~/hooks/usePageConfigContext"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const { Header, Content, Footer } = Layout

const DefaultLayout = () => {
  const { isAuthenticated, user, login, logout, loading } = useAuth()
  const { pageTitle, setPageTitle } = usePageConfigContext()
  const { pathname } = useLocation()

  const renderRoutes = () =>
    routes.map((route) => <PrivateRoute key={route.key || route.path} {...route} />)

  const onSelectMenu = ({ title }) => {
    setPageTitle(title)
  }

  return (
    <Layout className="site-layout overflow-hidden">
      {isAuthenticated && <CustomSider onSelect={onSelectMenu} />}
      <Layout className={`site-main`}>
        {!isAuthenticated && <LoginHeader login={login} loading={loading} />}

        {!pathname?.startsWith("/employee/") &&
          !routes
            .filter((r) => r.isPublic)
            .map((r) => r.path)
            .includes(pathname) && (
            <Header
              className="site-main-background"
              style={{
                padding: 0,
                ...(!isAuthenticated && {
                  justifyContent: "center",
                  backgroundColor: "var(--green)",
                  height: "6rem",
                }),
              }}
            >
              {isAuthenticated && (
                <Row justify={"space-between"} className={"header-bar"}>
                  <Col></Col>
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    <Row gutter={10} className="mt-[10px]">
                      <Col>
                        <Row style={{ flexDirection: "column" }}>
                          <Col className="text-end text-[16px] font-medium text-black">Demeter</Col>
                          <Col className="text-end text-[16px] font-medium text-black max-sm:hidden">
                            {user.email}
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ display: "flex", alignItems: "center" }}>
                        <Svg name={"avatar"} fill={"var(--primary-color)"} width={40} height={40} />
                      </Col>
                      <Col style={{ display: "grid", placeItems: "center" }}>
                        <Tooltip title="Đăng xuất">
                          <Svg
                            name={"logout"}
                            fill={"var(--primary-color)"}
                            width={24}
                            height={24}
                            onClick={logout}
                            style={{ marginRight: 12 }}
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Header>
          )}
        {isAuthenticated && (
          <Row className="page-title-container">
            <Col className={"page-title"}>{pageTitle}</Col>
            <Col style={{ display: "grid", placeItems: "center" }}>
              <div className={"page-title-line"} />
            </Col>
          </Row>
        )}
        <Content
          className={`site-main-background ${isAuthenticated ? "" : "container"} rounded-xl bg-white p-[24px] max-lg:px-[16px] lg:px-[24px] ${isAuthenticated ? "mx-[12px] my-[24px] min-h-screen" : ""} max-lg:p-0`}
          style={{
            ...(isAuthenticated && {
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
            }),
          }}
        >
          <Switch>
            {renderRoutes()}
            <Redirect to="/404" />
          </Switch>
        </Content>
        {!isAuthenticated ? (
          <div className="hidden lg:block">
            <LoginFooter />
          </div>
        ) : (
          <Footer className="footer">
            <div gutter={0} justify={"space-between"}>
              <div className="footer__topline" />
              <Row className="footer__content__container">
                <Col flex={10} className="site-brandname">
                  <div className={"description__group"}>Demeter @2022</div>
                </Col>
                <Col flex={1} className={"footer__content__description"}>
                  <div className={"description__group"}>
                    <Link to="/term-of-service">Term of service</Link>
                  </div>
                  <div className={"description__group"}>
                    <Link to="/private-policy">Private policy</Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Footer>
        )}
      </Layout>
    </Layout>
  )
}

export default DefaultLayout
