import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Progress,
  Row,
  Table,
} from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useSetState } from "react-use"
import { useApi, useProcedure, useProjectContext } from "~/hooks"
import queryString from "query-string"
import dayjs from "dayjs"
import { useHistory, useLocation } from "react-router-dom"
import { cloneProcedure, createProcedure } from "~/services/apis"
import { DownOutlined } from "@ant-design/icons"
import { DatePicker } from "~/components"

const DEFAULT_PARAMS = {
  search: "",
  page: 1,
  limit: 20,
}

function ProceduresPage() {
  const history = useHistory()
  const location = useLocation()
  const qs = queryString.parse(location.search)
  const [loading, setLoading] = useState(false)
  const { activeProject } = useProjectContext()
  const [cloneModalOpen, setCloneModalOpen] = useState(false)
  const [cloneProcedureId, setCloneProcedureId] = useState(null)
  const [cloneProcedureLoading, setCloneProcedureLoading] = useState(false)
  const [form] = Form.useForm()

  const [params, setParams] = useSetState(Object.keys(qs)?.length > 0 ? qs : { ...DEFAULT_PARAMS })

  const api = `
    query procedures($params: FetchDto!, $options: FetchProcedureDto!) {
        procedures(fetch: $params, options: $options) {
          id
          creatorId
          startDate
          completedAt
          executionDays
          creator {
            id
            firstName
            lastName
          }
          project {
            id
            name
          }
          name
          description
          createdAt
          updatedAt
        }
      }
  `

  const response = useApi(
    api,
    {
      params: {
        ...params,
        limit: params?.limit && +params.limit,
        page: params?.page && +params.page,
      },
      options: {
        ...(activeProject?.value && {
          projectId: activeProject?.value,
        }),
      },
    },
    {
      revalidateOnFocus: true,
    },
  )

  useEffect(
    () => history.replace(`${location.pathname}?${queryString.stringify(params)}`),
    // eslint-disable-next-line
    [params],
  )

  const parsedData = useMemo(() => {
    const { data, error } = response
    const headers = data?.headers

    return {
      error,
      data: data?.data?.procedures,
      meta: (headers && JSON.parse(headers.get("meta"))) || {},
    }
  }, [response])

  const onProcedureSubmit = async (values) => {
    try {
      setLoading(true)
      const result = await createProcedure(values)

      if (result.createProcedure) {
        message.success("Tạo quy trình thành công!")
        hideProcedureDrawer()

        response.mutate()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onProcedureRemove = () => {
    console.log("onProcedureRemove")
  }

  const columns = [
    {
      title: "Quy trình",
      key: "name",
      dataIndex: "name",
      width: 300,
      className: "c-p",
      render: (_, record) => {
        return (
          <>
            <div>
              <span className="font-semibold">{record?.name}</span>
            </div>
            <div>
              <span className="inline-block">Dự án:&nbsp;</span>
              <span className="font-semibold">{record?.project?.name}</span>
            </div>
          </>
        )
      },
    },
    {
      title: "Thông tin quy trình",
      key: "information",
      dataIndex: "information",
      className: "c-p",
      render: (_, record) => {
        const { startDate, executionDays } = record || {}
        const diffDays = startDate && dayjs().diff(dayjs(startDate), "day") + 1
        return (
          <>
            <div className="flex gap-[10px]">
              <div className="min-w-[50px] text-gray-500">
                • Tiến trình:{" "}
                <span className="ml-[4px] font-semibold">
                  {record?.startDate
                    ? dayjs().isAfter(record?.startDate)
                      ? record?.completedAt
                        ? dayjs(record?.completedAt).isAfter(dayjs())
                          ? "Đang diễn ra"
                          : "Đã hoàn thành"
                        : "Đang thực hiện"
                      : "Chưa bắt đầu"
                    : ""}{" "}
                  ({record?.startDate ? dayjs(record.startDate).format("DD/MM/YYYY") : ""}
                  {record?.completedAt
                    ? ` - ${dayjs(record.completedAt).format("DD/MM/YYYY")}`
                    : ""}
                  )
                </span>
              </div>
            </div>
            <div className="flex gap-[22px]">
              <div className="min-w-[60px] text-gray-500">• Mô tả:</div>
              <div className="line-clamp-1">{record?.description}</div>
            </div>
          </>
        )
      },
    },
    {
      title: " ",
      key: "action",
      dataIndex: "action",
      width: 150,
      render: (_, record) => {
        return (
          <div className="flex w-full justify-end" onClick={(e) => e.stopPropagation()}>
            {/* <Dropdown
              menu={{
                items: [
                  {
                    label: "Nhân bản quy trình",
                    key: "clone",
                    onClick: () => {
                      form.resetFields()
                      setCloneModalOpen(true)
                      setCloneProcedureId(record.id)
                    },
                  },
                ],
              }}
            >
              <span className="cursor-pointer font-medium text-primary-color">
                Hành động <DownOutlined className="text-[12px]" />
              </span>
            </Dropdown> */}
          </div>
        )
      },
    },
  ]

  const {
    show: showProcedureDrawer,
    hide: hideProcedureDrawer,
    ProcedureDrawer,
  } = useProcedure({
    loading,
    onSubmit: onProcedureSubmit,
    onRemove: onProcedureRemove,
  })

  const onCloneProcedure = async (values) => {
    try {
      setCloneProcedureLoading(true)
      const result = await cloneProcedure({
        ...values,
        procedureId: cloneProcedureId,
      })

      if (result.cloneProcedure) {
        message.success("Nhân bản quy trình thành công!")
        setCloneModalOpen(false)
        response.mutate()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setCloneProcedureLoading(false)
    }
  }

  return (
    <div className="max-lg:p-3">
      <div className="text-end">
        <Button type="primary" onClick={showProcedureDrawer}>
          Tạo quy trình
        </Button>
        <Divider />
      </div>

      <Row>
        <Col span={24}>
          <Table
            loading={response.isValidating}
            rowKey={"id"}
            columns={columns}
            scroll={{
              x: columns.reduce((acc, col) => {
                acc += col.width || 100

                return acc
              }, 300),
              scrollToFirstRowOnChange: true,
            }}
            dataSource={parsedData?.data}
            onRow={(record) => ({
              onClick: () => history.push(`/diaries/procedures/${record.id}`),
            })}
            pagination={{
              total: parsedData?.meta?.totalDocs,
              pageSize: params.limit,
              showSizeChanger: false,
              hideOnSinglePage: true,
              onChange: (page) => setParams({ page }),
              current: Number(params?.page || 1),
            }}
          />
        </Col>
      </Row>
      <Modal
        okButtonProps={{ loading: cloneProcedureLoading, onClick: form.submit }}
        open={cloneModalOpen}
        onCancel={() => {
          setCloneModalOpen(false)
          setCloneProcedureId(null)
        }}
      >
        <div className="mt-4">
          <Form form={form} onFinish={onCloneProcedure} layout="vertical">
            <Form.Item
              label="Tên quy trình mới"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên quy trình mới",
                },
              ]}
            >
              <Input placeholder="Nhập tên quy trình mới" />
            </Form.Item>

            <Form.Item
              label="Ngày bắt đầu quy trình"
              name="startDate"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập ngày bắt đầu quy trình",
                },
              ]}
            >
              <DatePicker inputReadonly className="w-full" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <ProcedureDrawer />
    </div>
  )
}

export default ProceduresPage
