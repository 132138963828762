import { Button, Col, message, Row } from "antd"
import React, { useState } from "react"
import { UserSelect } from "~/components"
import { updateStageTask } from "~/services/apis"

function AssignToModal({ stageTaskId, onFinish }) {
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(null)

  const handleSubmit = async () => {
    if (!selected) {
      message.error("Vui lòng chọn người giao việc!")
      return
    }

    try {
      setLoading(true)

      await updateStageTask(stageTaskId, {
        assignedToId: selected?.value,
      })

      onFinish?.()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="mt-5">
      <Row gutter={[10, 10]}>
        <Col xs={24} lg={18}>
          <UserSelect size="large" className={"w-full"} value={selected} onChange={setSelected} />
        </Col>
        <Col xs={24} lg={6}>
          <Button
            loading={loading}
            onClick={() => handleSubmit()}
            block
            size="large"
            type="primary"
          >
            Giao việc
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default AssignToModal
