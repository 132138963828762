import React, { useState, useCallback } from "react"
import { Drawer } from "antd"
import { DiaryContainer } from "~/components"

const useDiary = ({ procedureId, width = "80%", onClose } = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const DiaryDrawer = useCallback(() => {
    return (
      <Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Kiểm tra tình trạng hằng ngày"}
        open={visible}
        onClose={onCancel}
      >
        <DiaryContainer procedureId={procedureId} />
      </Drawer>
    )
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    DiaryDrawer,
  }
}

export default useDiary
