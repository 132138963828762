import { Tag } from "antd"
import React from "react"
import { TASK_PRIORITY } from "~/constants"

export default function TaskTag({ value }) {
  return (
    <Tag className="m-0" color={{ common: "blue", high: "gold", important: "red" }?.[value]}>
      {TASK_PRIORITY?.[value]}
    </Tag>
  )
}
