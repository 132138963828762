import { Button, Col, Divider, message, Row, Table, Tag } from "antd"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSetState } from "react-use"
import { useApi, useTask } from "~/hooks"
import queryString from "query-string"
import dayjs from "dayjs"
import { useHistory, useLocation } from "react-router-dom"
import { createTask, removeTask, updateTask } from "~/services/apis"
import TaskTag from "~/components/TaskTag"

const DEFAULT_PARAMS = {
  search: "",
  page: 1,
  limit: 20,
}

function TasksPage() {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const qs = queryString.parse(location.search)
  const [params, setParams] = useSetState(Object.keys(qs)?.length > 0 ? qs : { ...DEFAULT_PARAMS })
  const viewTaskRef = useRef(null)

  const api = `
    query tasks($params: FetchDto!) {
        tasks(fetch: $params) {
          id
          name
          description
          priority
          startDay
          executionDays
          spaceId
          imageUrls
          taskSupplies {
            id
            supply {
              id
              name
            }
            supplyId
            quantity
            unit
          }
          createdAt
          updatedAt
        }
      }
  `

  const response = useApi(
    api,
    {
      params: {
        ...params,
        limit: params?.limit && +params.limit,
        page: params?.page && +params.page,
      },
    },
    {
      revalidateOnFocus: true,
    },
  )

  useEffect(
    () => history.replace(`${location.pathname}?${queryString.stringify(params)}`),
    // eslint-disable-next-line
    [params],
  )

  const parsedData = useMemo(() => {
    const { data, error } = response
    const headers = data?.headers

    return {
      error,
      data: data?.data?.tasks,
      meta: (headers && JSON.parse(headers.get("meta"))) || {},
    }
  }, [response])

  const onTaskSubmit = async (values) => {
    try {
      setLoading(true)
      let result

      if (viewTaskRef.current) {
        result = await updateTask(viewTaskRef.current.id, values)
      } else {
        result = await createTask(values)
      }

      if (result.createTask) {
        hideTaskDrawer()
        message.success("Tạo công việc thành công!")
        viewTaskRef.current = null

        response.mutate()
      }

      if (result.updateTask) {
        hideTaskDrawer()
        message.success("Cập nhật công việc thành công!")
        viewTaskRef.current = null

        response.mutate()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onTaskRemove = async () => {
    try {
      const result = await removeTask(viewTaskRef.current.id)

      if (result.removeTask) {
        message.success("Xóa công việc thành công!")
        viewTaskRef.current = null
        hideTaskDrawer()

        response.mutate()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: "Công việc",
      key: "name",
      dataIndex: "name",
      width: 400,
      className: "c-p",
      render: (_, record) => {
        return <span className="line-clamp-1 font-semibold">{record?.name}</span>
      },
    },
    {
      title: "Mức độ",
      key: "priority",
      dataIndex: "priority",
      className: "c-p",
      width: 180,
      align: "center",
      render: (value) => {
        return value ? <TaskTag value={value} /> : "-"
      },
    },
    {
      title: "Mô tả",
      key: "description",
      dataIndex: "description",
      className: "c-p",
      width: 300,
      render: (value) => {
        return value ? <span className="line-clamp-2">{value}</span> : "-"
      },
    },
    {
      title: "Số ngày thực hiện",
      key: "executionDays",
      dataIndex: "executionDays",
      className: "c-p",
      render: function render(value) {
        return value
      },
    },
    {
      title: "Ngày tạo",
      key: "createdAt",
      dataIndex: "createdAt",
      className: "c-p",
      render: function render(value) {
        return value ? dayjs(value).format("DD-MM-YYYY") : "-"
      },
    },
  ]

  const {
    show: showTaskDrawer,
    hide: hideTaskDrawer,
    TaskDrawer,
  } = useTask({
    task: viewTaskRef.current,
    loading,
    onSubmit: onTaskSubmit,
    onRemove: onTaskRemove,
    onClose: () => {
      viewTaskRef.current = null
    },
  })

  return (
    <div>
      <div className="text-end">
        <Button type="primary" onClick={showTaskDrawer}>
          Tạo công việc
        </Button>
        <Divider />
      </div>

      <Row>
        <Col span={24}>
          <Table
            loading={response.isValidating}
            rowKey={"id"}
            columns={columns}
            scroll={{
              x: columns.reduce((acc, col) => {
                acc += col.width || 100

                return acc
              }, 300),
              scrollToFirstRowOnChange: true,
            }}
            dataSource={parsedData?.data}
            onRow={(record) => ({
              onClick: () => {
                viewTaskRef.current = {
                  ...record,
                  taskSupplies: record?.taskSupplies?.map((taskSupply) => ({
                    ...taskSupply,
                    supply: {
                      label: taskSupply?.supply?.name,
                      value: taskSupply?.supply?.id,
                    },
                  })),
                }
                showTaskDrawer()
              },
            })}
            pagination={{
              total: parsedData?.meta?.totalDocs,
              pageSize: params.limit,
              showSizeChanger: false,
              hideOnSinglePage: true,
              onChange: (page) => setParams({ page }),
              current: Number(params?.page || 1),
            }}
          />
        </Col>
      </Row>
      <TaskDrawer />
    </div>
  )
}

export default TasksPage
