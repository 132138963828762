import React, { useEffect, useState } from "react"
import { Select, Spin } from "antd"
import { useSetState } from "react-use"
import { debounce } from "lodash"
import { getGroups } from "~/services/apis"
import { useProjectContext } from "~/hooks"

const DEFAULT_PARAMS = {
  limit: 10,
  page: 1,
  search: "",
}

const GroupSelect = ({
  projectId = undefined,
  multiple = false,
  value = null,
  onChange = () => {},
  excludes = [],
  size = "middle",
  style = {},
}) => {
  const [params, setParams] = useSetState({ ...DEFAULT_PARAMS })
  const [groups, setGroups] = useState([])
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState(false)
  const { activeProject } = useProjectContext()

  const appendData = async () => {
    setLoading(true)
    try {
      const { data, headers } = await getGroups(params, {
        projectId: projectId || (activeProject?.value && activeProject.value),
      })
      setMeta((headers && JSON.parse(headers.get("meta"))) || {})
      setGroups([
        ...(params.page === 1 ? [] : groups),
        ...(data?.groups || [])
          ?.filter((group) => !excludes.includes(group?.id))
          ?.map((group) => ({
            label: group?.name,
            value: group?.id,
          })),
      ])
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    appendData()
    // eslint-disable-next-line
  }, [params, projectId])

  const onScroll = (e) => {
    if (
      !loading &&
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
    ) {
      params.page < meta?.totalPages && setParams({ page: params.page + 1 })
    }
  }

  return (
    <Select
      labelInValue
      value={value}
      size={size}
      showSearch={true}
      onPopupScroll={onScroll}
      notFoundContent={"No Group Found"}
      onSearch={debounce((value) => setParams({ search: value }), 300)}
      mode={multiple && "multiple"}
      options={groups}
      onChange={onChange}
      allowClear={true}
      onClear={() => onChange(null)}
      dropdownRender={(menu) => (
        <>
          {menu}
          {loading && (
            <div className="place-center" style={{ padding: "8px 0" }}>
              <Spin />
            </div>
          )}
        </>
      )}
      style={{ ...style }}
      placeholder="Chọn khu vực..."
    />
  )
}

export default GroupSelect
