import { Button, Col, Divider, Form, Input, Popconfirm, Radio, Row } from "antd"
import { DEVICE_CONNECT_PROTOCOLS } from "~/constants"
import React, { useEffect } from "react"
import CategorySelect from "../CategorySelect"
import Upload from "../Upload"

const DeviceProfileForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  const onFinish = (values) => {
    onSubmit(values)
  }

  return (
    <div>
      <Form
        labelCol={{ span: 6 }}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Product Name"}
              name="name"
              rules={[{ required: true, message: "Name is required !" }]}
            >
              <Input placeholder={"Please input name"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Product Image"}
              name="imageUrl"
              rules={[{ required: true, message: "Image is required !" }]}
            >
              <Upload description={"Upload an image of 800 x 800 pixels."} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Product Description"}
              name="description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input placeholder={"Please input description"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Protocol"}
              name="protocol"
              rules={[{ required: true, message: "Protocol is required" }]}
            >
              <Radio.Group
                options={Object.keys(DEVICE_CONNECT_PROTOCOLS).map((protocol) => ({
                  label: DEVICE_CONNECT_PROTOCOLS[protocol],
                  value: protocol,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item label={"Product Category(ies)"} name="categories">
              <CategorySelect treeFormat={true} multiple={true} excludes={[id]} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="end">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              {id ? "Save" : "Create"}
            </Button>
          </div>
          {id && (
            <div>
              <Popconfirm title={"Are you sure to remove this function ?"} onConfirm={onRemove}>
                <Button loading={loading} type={"danger"} style={{ marginLeft: 16 }}>
                  Remove device profile
                </Button>
              </Popconfirm>
            </div>
          )}
        </Row>
        <Form.Item hidden={true} name={"standardFunctionIds"} />
      </Form>
    </div>
  )
}

export default DeviceProfileForm
