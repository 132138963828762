import React, { useEffect, useState } from "react"
import { Select, Spin } from "antd"
import { useSetState } from "react-use"
import { debounce } from "lodash"
import { getDeviceProfiles } from "~/services/apis"

const DEFAULT_PARAMS = {
  limit: 10,
  page: 1,
  search: "",
}

const DeviceProfileSelect = ({
  disabled,
  multiple = false,
  value,
  onChange = () => {},
  excludes = [],
  style,
}) => {
  const [params, setParams] = useSetState({ ...DEFAULT_PARAMS })
  const [deviceProfiles, setDeviceProfiles] = useState([])
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState(false)

  const appendData = async () => {
    setLoading(true)
    try {
      const { data, headers } = await getDeviceProfiles(params)
      setMeta((headers && JSON.parse(headers.get("meta"))) || {})
      setDeviceProfiles([
        ...(params.page === 1 ? [] : deviceProfiles),
        ...(data?.deviceProfiles || [])
          ?.filter((i) => !excludes.includes(i?.id))
          ?.map((i) => ({
            label: i?.name,
            value: i?.id,
            fields: i,
          })),
      ])
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    appendData()
    // eslint-disable-next-line
  }, [params])

  const onScroll = (e) => {
    if (
      !loading &&
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
    ) {
      params.page < meta?.totalPages && setParams({ page: params.page + 1 })
    }
  }

  return (
    <Select
      labelInValue
      disabled={disabled}
      value={value}
      showSearch={true}
      onPopupScroll={onScroll}
      notFoundContent={"No device profiles found"}
      onSearch={debounce((value) => setParams({ search: value }), 300)}
      mode={multiple && "multiple"}
      options={deviceProfiles}
      onChange={(value, option) => onChange(value, option?.fields)}
      allowClear={true}
      style={style}
      onClear={() => onChange(null)}
      dropdownRender={(menu) => (
        <>
          {menu}
          {loading && (
            <div className="place-center" style={{ padding: "8px 0" }}>
              <Spin />
            </div>
          )}
        </>
      )}
      placeholder="Choose device profile..."
    />
  )
}

export default DeviceProfileSelect
