import React, { useMemo, useCallback } from "react"
import { message, Select, TreeSelect, Spin } from "antd"
import { useApi } from "~/hooks"
import { useSetState } from "react-use"
import { debounce } from "lodash"

const DEFAULT_PARAMS = {
  limit: 9999,
  page: 1,
  search: "",
}

const { SHOW_CHILD } = TreeSelect

const CategorySelect = ({
  treeFormat = false,
  multiple = false,
  value = null,
  parentOnly = false,
  onChange = () => {},
  excludes = [],
}) => {
  const [params, setParams] = useSetState({ ...DEFAULT_PARAMS })
  const response = useApi(
    `
      query($fetch: FetchDto) {
        categories(fetch: $fetch) {
          id
          name
          parentId
        }
      }
    `,
    {
      refreshInterval: 0,
      fetch: params,
    },
  )

  const createTree = useCallback(
    (items, id = null, link = "parentId") =>
      items
        ?.map((i) => ({ ...i, parentId: i.parentId || null }))
        ?.filter((item) => item[link] === id)
        ?.map((item) => ({
          ...item,
          title: item?.name,
          value: item.id,
          key: item.id,
          children: createTree(items, item.id),
        })),
    [],
  )

  const parsedCategories = useMemo(() => {
    const { data, error } = response

    if (error) {
      message.error(error)
    }

    const categoriesData = data?.data?.categories

    if (treeFormat) {
      return createTree(categoriesData)
    }

    return categoriesData
      ?.filter((i) => !excludes.includes(i?.id))
      ?.filter((cat) => (parentOnly ? !cat?.parentId : true))
      ?.map((i) => ({
        label: i?.name,
        value: i?.id,
      }))
  }, [createTree, treeFormat, excludes, response, parentOnly])

  return !treeFormat ? (
    <Select
      defaultValue={multiple ? value?.map((item) => item?.id) : value}
      showSearch={true}
      notFoundContent={
        response?.isValidating ? (
          <div className="grid-center">
            <Spin size="small" />
          </div>
        ) : (
          "No categories found"
        )
      }
      onSearch={debounce((value) => setParams({ search: value }), 300)}
      mode={multiple && "multiple"}
      options={parsedCategories}
      onChange={(value) => onChange(value || null)}
      allowClear
      placeholder="Choose category(ies)..."
    />
  ) : (
    <TreeSelect
      defaultValue={multiple ? value?.map((item) => item?.id) : value}
      treeData={parsedCategories}
      showCheckedStrategy={SHOW_CHILD}
      onChange={(value) => onChange(value || null)}
      allowClear
      treeCheckable={{
        checkable: true,
        isLeaf: true,
      }}
      style={{ width: "100%" }}
    />
  )
}

export default CategorySelect
