import AES from "crypto-js/aes"
import CryptoJS from "crypto-js"

export const parseSafe = (string) => {
  try {
    const result = JSON.parse(string)

    return result
  } catch (e) {
    return undefined
  }
}

export const decryptAES = (encryptedMessage, key) => {
  const bytes = AES.decrypt(encryptedMessage, key || process.env.REACT_APP_AES_KEY)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)

    reader.onerror = (error) => reject(error)
  })

export const getAvatarBackgroundColor = (firstCharacter) => {
  const colorByCharacter = {
    A: "#FFD180",
    B: "#FF8A65",
    C: "#64B5F6",
    D: "#81C784",
    E: "#4DD0E1",
    F: "#BA68C8",
    G: "#FFD180",
    H: "#FF8A65",
    I: "#64B5F6",
    J: "#81C784",
    K: "#BA68C8",
    L: "#4DD0E1",
    M: "#FFD180",
    N: "#FF8A65",
    O: "#64B5F6",
    P: "#81C784",
    Q: "#BA68C8",
    R: "#4DD0E1",
    S: "#FFD180",
    T: "#FF8A65",
    U: "#64B5F6",
    V: "#81C784",
    W: "#BA68C8",
    X: "#4DD0E1",
    Y: "#FFD180",
    Z: "#FF8A65",
  }

  return colorByCharacter?.[firstCharacter] || "#FF8A65"
}

export const escapeHtml = (string) => {
  if (!string) return ""

  return string?.replace(/<(.|\n)*?>/g, "")?.replaceAll(/&nbsp;/g, " ")
}
