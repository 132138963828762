import React from "react"
import { Input } from "antd"
import "./styles.less"

const TextField = ({
  label,
  labelStyle = {},
  value = "",
  onChange,
  defaultValue,
  type = "text",
  textOnly = false,
  onPressEnter = () => {},
}) => {
  if (textOnly) {
    return (
      <>
        <div className="pure-material-textonly-outlined">
          <div className="textonly-text">{value}</div>
          <span className="textonly-label">{label}</span>
        </div>
      </>
    )
  } else
    return (
      <label className={`pure-material-textfield-outlined`}>
        <Input
          onPressEnter={onPressEnter}
          value={value}
          type={type}
          onChange={onChange}
          placeholder=""
          defaultValue={defaultValue}
        />
        <span style={labelStyle}>{label}</span>
      </label>
    )
}

export default TextField
