import React, { useCallback, useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button, Popconfirm, Select, AutoComplete } from "antd"
import { isEmpty, isEqual } from "lodash"
import { Upload } from ".."
import { useAuth } from "~/hooks"
import { PRODUCT_UNIT, SUPPLY_KIND } from "~/constants"
import CustomEditor from "../Editor"
import { debounce } from "lodash"

const SupplyForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const { user } = useAuth()
  const [form] = Form.useForm()

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = useCallback(
    debounce((values) => {
      const { project, stages, ...rest } = values
      onSubmit({
        ...rest,
      })
    }, 300),
    [],
  )

  return (
    <div>
      <Form
        labelCol={{ span: 6 }}
        colon={false}
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} lg={24}>
            <Form.Item label={"Hình ảnh vật tư"} name="imageUrls">
              <Upload description={"Tải ảnh vật tư (800 x 800)"} multiple />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              rules={[{ required: true, message: "Tên vật tư nông nghiệp là bắt buộc" }]}
              label={"Tên vật tư nông nghiệp"}
              name="name"
            >
              <Input placeholder={"Nhập tên vật tư nông nghiệp"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              rules={[{ required: true, message: "Danh mục vật tự là bắt buộc" }]}
              label={"Danh mục"}
              name="kind"
            >
              <Select
                options={Object.values(SUPPLY_KIND).map((i) => ({ label: i, value: i }))}
                className="w-full"
                placeholder={"Chọn danh mục"}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Nhà sản xuất"} name="producerName">
              <Input placeholder={"Nhập tên nhà sản xuất"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Thương hiệu"} name="brandName">
              <Input placeholder={"Nhập tên thương hiệu"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Đơn vị tính"} name="unit">
              <AutoComplete options={PRODUCT_UNIT.map((unit) => ({ label: unit, value: unit }))}>
                <Input placeholder="Đơn vị tính" />
              </AutoComplete>
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Đặc tính"} name="features">
              <Input.TextArea rows={3} placeholder={"Nhập đặc tính"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Link mua vật tư"} name="url">
              <Input placeholder={"Nhập link mua vật tư"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Mô tả"} name="description">
              <CustomEditor />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              Lưu
            </Button>
            <Button
              onClick={onCancel}
              disabled={loading}
              type={"outline"}
              style={{ marginLeft: 16 }}
            >
              Hủy
            </Button>
          </div>
          {id && user?.role !== "employee" && (
            <div>
              <Popconfirm
                title={"Bạn có chắc chắn muốn xóa vật tư nông nghiệp này ?"}
                onConfirm={onRemove}
              >
                <Button loading={loading} type={"danger"} style={{ marginLeft: 16 }}>
                  Xóa vật tư nông nghiệp
                </Button>
              </Popconfirm>
            </div>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default SupplyForm
