import { DeleteOutlined, EyeOutlined } from "@ant-design/icons"
import { Modal, Popconfirm } from "antd"
import React, { useState } from "react"
import "./styles.less"

export default function ActionImage({
  src,
  date,
  previewable,
  removable,
  onRemove = () => {},
  style,
}) {
  const [preview, setPreview] = useState(false)

  return (
    <div
      style={{
        display: "inline-block",
        borderRadius: 3,
        border: "1px solid var(--very-light-gray)",
        padding: 6,
        width: "100%",
      }}
      className={"action-image-container"}
    >
      <img className={"action-image"} src={src} style={style} alt={src} />
      <div className="actions">
        {previewable && (
          <EyeOutlined onClick={() => setPreview(true)} style={{ color: "white", fontSize: 16 }} />
        )}
        {removable && (
          <Popconfirm title={"Are you sure to delete this item ?"} onConfirm={onRemove}>
            <DeleteOutlined style={{ color: "white", fontSize: 16 }} />
          </Popconfirm>
        )}
      </div>
      {date && (
        <div className="action-image-date whitespace-nowrap px-2">
          <div>{date?.split(" ")?.[0] || ""}</div>
          <div>
            {date?.split(" ")?.[1] || ""} {date?.split(" ")?.[2] || ""}
          </div>
        </div>
      )}
      <Modal
        open={preview}
        onCancel={() => setPreview(false)}
        width={"80%"}
        footer={null}
        closeIcon={() => null}
        style={{ maxWidth: 1000 }}
        bodyStyle={{ padding: 0 }}
      >
        <img src={src} width={"100%"} height={"100%"} style={{ aspectRatio: "16 / 9" }} alt={src} />
      </Modal>
    </div>
  )
}
