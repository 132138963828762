import React, { useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button, message } from "antd"
import { isEmpty, isEqual } from "lodash"
import { Upload } from ".."

const CommentForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = (values) => {
    const { imageUrls, content } = values

    if (!content && !imageUrls?.length) {
      message.error("Vui lòng nhập nội dung hoặc thêm hình ảnh!")
      return
    }

    onSubmit({
      ...values,
    })
  }

  return (
    <div>
      <Form
        layout={"vertical"}
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24}>
            <Form.Item label={"Nội dung (nếu có)"} name="content">
              <Input.TextArea rows={4} placeholder={"Nhập nội dung thảo luận"} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label={"Hình ảnh thêm (nếu có)"} name="imageUrls">
              <Upload multiple />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              Lưu
            </Button>
            <Button
              onClick={onCancel}
              disabled={loading}
              type={"outline"}
              style={{ marginLeft: 16 }}
            >
              Hủy
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  )
}

export default CommentForm
