import { Tag } from "antd"
import React from "react"
import { ROLE } from "~/constants"
import { useAuth } from "~/hooks"

export default function UserTag({ value }) {
  const { isAdmin } = useAuth()

  const role = {
    ...ROLE,
    ...(!isAdmin && { user: "Chủ dự án" }),
  }

  return (
    <Tag
      className="m-0"
      color={{ user: "var(--primary-color)", employee: "gray", admin: "blue" }?.[value]}
    >
      {role?.[value]}
    </Tag>
  )
}
