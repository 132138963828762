import React, { useCallback, useState } from "react"
import { Drawer } from "antd"
import { CameraForm } from "~/components"

const useCamera = ({
  camera,
  loading = false,
  width = "60%",
  onClose,
  onSubmit,
  onRemove,
} = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const CameraDrawer = useCallback(() => {
    return (
      <Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Camera"}
        open={visible}
        onClose={onCancel}
      >
        <CameraForm
          id={camera?.id}
          initialValues={camera}
          onSubmit={onSubmit}
          onConfirmDelete={onRemove}
          onCancel={onCancel}
          loading={loading}
        />
      </Drawer>
    )
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    CameraDrawer,
  }
}

export default useCamera
