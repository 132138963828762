import React, { useState, Fragment, useEffect } from "react"
import { Row, Col, Form, Tag, Drawer, Input, Radio, Divider, Button, Popconfirm, Modal } from "antd"
import { useSetState } from "react-use"
import { DEVICE_FUNCTION_TYPE, DEVICE_FUNCTION_TRANSFER_TYPE } from "~/constants"
import Parameter from "./parameter"
import * as dayjs from "dayjs"
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import FunctionTypeDescription from "../FunctionTypeDescription"

const DeviceFunctionForm = ({
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const [showModal, setShowModal] = useState(false)
  const id = initialValues?.id
  const isEditting = id && id !== "create"
  const [drawerContent, setDrawerContent] = useSetState({
    index: null,
    edit: null,
  })
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  const onFinish = (values) => {
    if (values.type === "property") {
      values.parameters[0].name = values.name
      values.parameters[0].key = values.key
    }

    onSubmit(values)
  }

  const handleCloseDrawer = (isEdit) => {
    const values = form.getFieldValue("parameters")
    if (!isEdit && values?.length > 0) {
      values.pop()
      form.setFieldsValue({ parameters: values })
    }
    setDrawerContent({ index: null, edit: null })
  }

  const renderParameters = ({ type }) => {
    if (type === "property") {
      return (
        <Form.List name={"parameters"}>
          {(fields) => {
            return (
              <Fragment key={`params_${fields?.[0]?.name}`}>
                {Number.isInteger(fields?.[0]?.name) && (
                  <Parameter form={form} paramIndex={fields?.[0]?.name} />
                )}
              </Fragment>
            )
          }}
        </Form.List>
      )
    }

    const validateParameters = async () => {
      try {
        await form.validateFields()
        setDrawerContent({ index: null, edit: true })
      } catch ({ errorFields }) {
        if (!errorFields.some((field) => field?.name?.[0] === "parameters")) {
          //if parameters has 0 errors.
          setDrawerContent({ index: null, edit: true })
        }
      }
    }

    return (
      (<Form.Item noStyle shouldUpdate>
        {() => (
          <Form.List name={"parameters"}>
            {(fields, { add, remove }) => (
              <>
                {form.getFieldValue("type") === "action" && (
                  <Row style={{ marginBottom: 16 }} gutter={[8, 16]}>
                    <Col span={6}>Input Parameters:</Col>
                    <Col span={18}>
                      {fields.map((field) => {
                        const parameter = form.getFieldValue("parameters")?.[field.name]
                        return parameter.parameterType === "input" ? (
                          <Form.Item noStyle name={[field.name, "name"]} key={field.key}>
                            <Tag
                              onClick={() => {
                                setDrawerContent({ index: field.name, edit: true })
                              }}
                              style={{ marginBottom: 8, cursor: "pointer" }}
                              closable={true}
                              onClose={() => remove(field.name)}
                            >
                              {parameter.name}
                            </Tag>
                          </Form.Item>
                        ) : null
                      })}
                      <div
                        onClick={() => {
                          add({
                            name: "",
                            key: "",
                            dataType: "Value",
                            parameterType: "input",
                            values: JSON.stringify({}),
                          })
                          setDrawerContent({
                            index: form.getFieldValue("parameters")?.length - 1,
                            edit: false,
                          })
                        }}
                        style={{
                          cursor: "pointer",
                          color: "var(--primary-color)",
                          fontWeight: 500,
                        }}
                      >
                        + Add Input Parameter
                      </div>
                    </Col>
                    <Col span={18} offset={6}></Col>
                  </Row>
                )}
                <Row style={{ marginBottom: 16 }} gutter={[8, 16]}>
                  <Col span={6}>Output Parameters:</Col>
                  <Col span={18}>
                    {fields.map((field) => {
                      const parameter = form.getFieldValue("parameters")?.[field.name]
                      return parameter.parameterType === "output" ? (
                        <Form.Item noStyle name={[field.name, "name"]} key={field.key}>
                          <Tag
                            onClick={() => {
                              setDrawerContent({ index: field.name, edit: true })
                            }}
                            style={{ marginBottom: 8, cursor: "pointer" }}
                            closable={true}
                            onClose={() => remove(field.name)}
                          >
                            {parameter.name}
                          </Tag>
                        </Form.Item>
                      ) : null
                    })}
                    <div
                      onClick={() => {
                        add({
                          dataType: "Value",
                          parameterType: "output",
                          values: JSON.stringify({}),
                        })
                        setDrawerContent({
                          index: form.getFieldValue("parameters")?.length - 1,
                          edit: false,
                        })
                      }}
                      style={{
                        cursor: "pointer",
                        color: "var(--primary-color)",
                        fontWeight: 500,
                      }}
                    >
                      + Add Output Parameter
                    </div>
                  </Col>
                  <Col span={18} offset={6}></Col>
                </Row>
                <Drawer
                  title={"Function"}
                  placement="right"
                  onClose={() => handleCloseDrawer(drawerContent.edit)}
                  open={drawerContent.index !== null}
                  destroyOnClose={true}
                >
                  {Number.isInteger(drawerContent.index) && (
                    <Parameter form={form} paramIndex={drawerContent.index} />
                  )}
                  <Divider />
                  <Row justify={"end"}>
                    <Button onClick={validateParameters} type={"primary"} style={{ marginLeft: 8 }}>
                      Save
                    </Button>
                  </Row>
                </Drawer>
              </>
            )}
          </Form.List>
        )}
      </Form.Item>)
    );
  }

  const onValuesChange = (changeFields) => {
    const keys = Object.keys(changeFields)
    if (keys.includes("type")) {
      form.setFieldsValue({ parameters: changeFields["type"] === "property" ? [{}] : [] })
    }
  }

  return (
    (<div>
      <Form
        layout={"vertical"}
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Row gutter={20}>
          <Col id={"main-information"} span={12}>
            <Form.Item
              label={
                <span>
                  Function Type&nbsp;&nbsp;
                  <InfoCircleOutlined className="c-p" onClick={() => setShowModal(true)} />
                </span>
              }
              name="type"
              rules={[{ required: true, message: "Function type is required !" }]}
            >
              <Radio.Group
                options={Object.keys(DEVICE_FUNCTION_TYPE).map((item) => ({
                  label: DEVICE_FUNCTION_TYPE[item],
                  value: item,
                }))}
                placeholder={"Please select function type"}
              />
            </Form.Item>
            <Form.Item
              label={"Name"}
              name="name"
              validateTrigger={["onChange", "onBlur"]}
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input placeholder={"Please input name"} />
            </Form.Item>
            <Form.Item
              label={"Identifier"}
              name="key"
              rules={[
                { required: true, message: "Identifier is required !" },
                {
                  validator(_, value) {
                    if (value.match(/^[a-z0-9_]+$/)) {
                      return Promise.resolve()
                    }

                    return Promise.reject(
                      new Error(
                        "Identifier must only contain lowercase letters, numbers and underscores !",
                      ),
                    )
                  },
                },
              ]}
            >
              <Input placeholder={"Please input identifier"} />
            </Form.Item>
            <Form.Item
              label={"Transfer Type"}
              name="transferType"
              rules={[{ required: true, message: "Transfer type is required !" }]}
            >
              <Radio.Group
                options={Object.keys(DEVICE_FUNCTION_TRANSFER_TYPE).map((item) => ({
                  label: DEVICE_FUNCTION_TRANSFER_TYPE[item],
                  value: item,
                }))}
                placeholder={"Please select function type"}
              />
            </Form.Item>
            <Form.Item label={"Note"} name="note">
              <Input.TextArea placeholder={"Fill in note...."} />
            </Form.Item>
            {isEditting && (
              <Row>
                <Col span={12}>
                  <Form.Item noStyle shouldUpdate>
                    {() => (
                      <Form.Item label={"Created at"} name="createdAt">
                        {form.getFieldValue("createdAt") &&
                          dayjs(form.getFieldValue("createdAt")).format("DD/MM/YYYY")}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item noStyle shouldUpdate>
                    {() => (
                      <Form.Item label={"Last updated"} name="updatedAt">
                        {form.getFieldValue("updatedAt") &&
                          dayjs(form.getFieldValue("updatedAt")).format("DD/MM/YYYY")}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
          <Col id={"parameters"} span={12}>
            <Form.Item noStyle shouldUpdate={true}>
              {({ getFieldsValue }) => {
                return renderParameters(getFieldsValue())
              }}
            </Form.Item>
            <span className="ant-form-item-label">Aliases</span>
            <Form.List name="aliases">
              {(fields, { add, remove }) => (
                <Row gutter={20}>
                  {fields.map(({ key, name, ...restField }) => (
                    <Col xs={24} lg={24} key={key}>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Form.Item label={"Function key"} {...restField} name={[name, "key"]}>
                            <Input placeholder="Input alias key" />
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item label={"Value"} {...restField} name={[name, "value"]}>
                            <Input placeholder="Input alias value" />
                          </Form.Item>
                        </Col>
                        <Col span={1} className={"place-center"}>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                  <Col style={{ display: "grid", placeItems: "start" }}>
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add alias
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.List>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              Submit
            </Button>
            <Button
              onClick={onCancel}
              disabled={loading}
              type={"outline"}
              style={{ marginLeft: 16 }}
            >
              Cancel
            </Button>
          </div>
          {id && (
            <div>
              <Popconfirm title={"Are you sure to remove this function ?"} onConfirm={onRemove}>
                <Button loading={loading} type={"danger"} style={{ marginLeft: 16 }}>
                  Remove function
                </Button>
              </Popconfirm>
            </div>
          )}
        </Row>
        <Modal
          width={800}
          style={{ top: 50 }}
          title={"Function Type Description"}
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <FunctionTypeDescription />
        </Modal>
      </Form>
    </div>)
  );
}

export default DeviceFunctionForm
