import React, { useEffect, useState } from "react"
import { Select, Spin } from "antd"
import { useSetState } from "react-use"
import { debounce } from "lodash"
import { getProcedures } from "~/services/apis"

const DEFAULT_PARAMS = {
  limit: 10,
  page: 1,
  search: "",
}

const ProcedureSelect = ({
  className,
  multiple = false,
  value = null,
  onChange = () => {},
  excludes = [],
  allowClear = true,
}) => {
  const [params, setParams] = useSetState({ ...DEFAULT_PARAMS })
  const [procedures, setProcedures] = useState([])
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState(false)

  const appendData = async () => {
    setLoading(true)
    try {
      const { data, headers } = await getProcedures(params)

      setMeta((headers && JSON.parse(headers.get("meta"))) || {})
      setProcedures([
        ...(params.page === 1 ? [] : procedures),
        ...(data?.procedures || [])
          ?.filter((procedure) => !excludes.includes(procedure?.id))
          ?.map((procedure) => {
            return {
              label: procedure?.name,
              value: procedure?.id,
            }
          }),
      ])
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    appendData()
    // eslint-disable-next-line
  }, [params])

  const onScroll = (e) => {
    if (
      !loading &&
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
    ) {
      params.page < meta?.totalPages && setParams({ page: params.page + 1 })
    }
  }

  return (
    <Select
      labelInValue
      value={value}
      showSearch={true}
      notFoundContent={"Không tìm thấy quy trình"}
      onPopupScroll={onScroll}
      onSearch={debounce((search) => setParams({ search, page: 1 }), 300)}
      mode={multiple && "multiple"}
      options={procedures}
      onChange={onChange}
      allowClear={allowClear}
      filterOption={false}
      dropdownRender={(menu) => (
        <>
          {menu}
          {loading && (
            <div className="place-center" style={{ padding: "8px 0" }}>
              <Spin />
            </div>
          )}
        </>
      )}
      onClear={() => onChange(null)}
      className={className}
      placeholder="Chọn quy trình"
    />
  )
}

export default ProcedureSelect
