import React, { useEffect, useState } from "react"
import { Select, Spin } from "antd"
import { useSetState } from "react-use"
import { debounce } from "lodash"
import { getUsers } from "~/services/apis"
import UserTag from "../UserTag"

const DEFAULT_PARAMS = {
  limit: 10,
  page: 1,
  search: "",
}

const UserSelect = ({
  multiple = false,
  value = null,
  onChange = () => {},
  excludes = [],
  style = {},
  size = "middle",
  ...props
}) => {
  const [params, setParams] = useSetState({ ...DEFAULT_PARAMS })
  const [users, setUsers] = useState([])
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState(false)

  const appendData = async () => {
    setLoading(true)
    try {
      const { data, headers } = await getUsers(params)
      setMeta((headers && JSON.parse(headers.get("meta"))) || {})
      setUsers([
        ...(params.page === 1 ? [] : users),
        ...(data?.users || [])
          ?.filter((usr) => !excludes.includes(usr?.id))
          ?.map((usr) => ({
            label: (
              <span>
                {usr?.firstName} {usr?.lastName} / <UserTag value={usr?.role} />
              </span>
            ),
            value: usr?.id,
          })),
      ])
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    appendData()
    // eslint-disable-next-line
  }, [params])

  const onScroll = (e) => {
    if (
      !loading &&
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
    ) {
      params.page < meta?.totalPages && setParams({ page: params.page + 1 })
    }
  }

  return (
    <Select
      labelInValue
      value={value}
      size={size}
      showSearch={true}
      onPopupScroll={onScroll}
      notFoundContent={"Không tìm thấy người dùng/nhân viên"}
      onSearch={debounce((value) => setParams({ search: value }), 300)}
      mode={multiple && "multiple"}
      options={users}
      onChange={onChange}
      allowClear={true}
      onClear={() => onChange(null)}
      dropdownRender={(menu) => (
        <>
          {menu}
          {loading && (
            <div className="place-center" style={{ padding: "8px 0" }}>
              <Spin />
            </div>
          )}
        </>
      )}
      style={{ ...style }}
      placeholder="Chọn..."
      {...props}
    />
  )
}

export default UserSelect
