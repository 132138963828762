import { PlusOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Table, Tag } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useSetState } from "react-use"
import { SUPPLY_STOCK_CARD_TYPE } from "~/constants"
import { createSupplyStockCard, getSupplyStockCards } from "~/services/apis"

const DEFAULT_PARAMS = {
  limit: 10,
  page: 1,
  search: "",
}

function SupplyStockCardForm({ supply }) {
  const [params, setParams] = useSetState({ ...DEFAULT_PARAMS })
  const [stockCards, setStockCards] = useState([])
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState(false)
  const [showAddSupplyStockCardModal, setShowAddSupplyStockCardModal] = useState(false)
  const [addingSupplyStockCard, setAddingSupplyStockCard] = useState(false)

  const [form] = Form.useForm()

  const fetchSupplyStockCards = async (supplyId) => {
    setLoading(true)
    try {
      const { data, headers } = await getSupplyStockCards(params, { supplyId })

      setMeta((headers && JSON.parse(headers.get("meta"))) || {})
      setStockCards(data?.supplyStockCards || [])
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchSupplyStockCards(supply?.id)
  }, [supply?.id, params])

  const columns = [
    {
      title: "Phân loại",
      dataIndex: "type",
      align: "center",
      width: 150,
      render: (type) => {
        const colors = {
          purchase: "green",
          usage: "orange",
        }

        return <Tag color={colors[type]}>{SUPPLY_STOCK_CARD_TYPE?.[type] || ""}</Tag>
      },
    },
    {
      title: "Số lượng thay đổi",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      align: "center",
      render: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "Số lượng tồn kho",
      dataIndex: "onHand",
      key: "onHand",
      width: 150,
      align: "center",
      render: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "Giá vật tư tại thời điểm thay đổi",
      dataIndex: "price",
      key: "price",
      width: 200,
      align: "center",
      render: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      align: "center",
      width: 250,
    },
    {
      title: "Ngày thực hiện",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (value) => value && dayjs(value).format("DD/MM/YYYY HH:mm"),
    },
  ]

  const handleAddSupplyStockCard = async (values) => {
    try {
      setAddingSupplyStockCard(true)
      const result = await createSupplyStockCard({
        ...values,
        supplyId: supply?.id,
        type: "purchase",
      })

      if (result.createSupplyStockCard) {
        fetchSupplyStockCards(supply?.id)
      }

      setShowAddSupplyStockCardModal(false)
      setAddingSupplyStockCard(false)
    } catch (e) {
      console.log(e.message)
    } finally {
      setAddingSupplyStockCard(false)
    }
  }

  return (
    <div>
      <div className="mb-3 flex justify-between gap-5">
        <div>
          <span className="text-[15px]">
            Số lượng có thể sử dụng{" "}
            <b>{supply?.available?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "--"}</b>
          </span>
          <span className="line-clamp-3 font-medium text-gray-500">
            * Số lượng có thể sử dụng là số lượng tồn kho đã trừ đi lượng tồn được gán cho các công
            việc chưa hoàn thành.
          </span>
        </div>
        <Button
          type="primary"
          className="mb-2"
          onClick={() => setShowAddSupplyStockCardModal(true)}
        >
          <PlusOutlined />
          Nhập vật tư
        </Button>
      </div>
      <Modal
        title="Nhập vật tư"
        open={showAddSupplyStockCardModal}
        onCancel={() => setShowAddSupplyStockCardModal(false)}
        okButtonProps={{
          loading: addingSupplyStockCard,
          onClick: () => form.submit(),
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleAddSupplyStockCard}>
          {/* <Form.Item
            name="type"
            label="Phân loại"
            rules={[{ required: true, message: "Phân loại là bắt buộc!" }]}
          >
            <Select
              options={Object.entries(SUPPLY_STOCK_CARD_TYPE).map(([key, value]) => ({
                label: value,
                value: key,
              }))}
              placeholder="Chọn phân loại"
            />
          </Form.Item> */}

          <Row gutter={[16, 0]} className="">
            <Col span={12}>
              <Form.Item
                name="quantity"
                label="Số lượng"
                rules={[{ required: true, message: "Số lượng là bắt buộc!" }]}
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  min={0}
                  className="w-full"
                  placeholder="Nhập số lượng"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="price"
                label="Giá vật tư"
                rules={[{ required: true, message: "Giá vật tư là bắt buộc!" }]}
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  min={0}
                  className="w-full"
                  placeholder="Nhập giá vật tư"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="note" label="Ghi chú">
            <Input.TextArea placeholder="Nhập ghi chú" />
          </Form.Item>
        </Form>
      </Modal>
      <Table
        scroll={{ x: columns.reduce((acc, curr) => acc + curr.width, 300) }}
        columns={columns}
        dataSource={stockCards}
        loading={loading}
        pagination={{
          total: meta?.totalDocs,
          pageSize: params?.limit,
          showSizeChanger: false,
          hideOnSinglePage: true,
          onChange: (page) => setParams({ page }),
          current: Number(params?.page || 1),
        }}
      />
    </div>
  )
}

export default SupplyStockCardForm
