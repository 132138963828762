import React, { useRef, useState } from "react"
import { Upload, Row, Col, Button, Modal } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import { uploadImage } from "~/services/apis"
import { useSetState } from "react-use"
import { getBase64 } from "~/utils"

export default function CustomUpload({
  value,
  onChange,
  description = "",
  disabled = false,
  multiple = false,
  readOnly = false,
}) {
  const [preview, setPreview] = useSetState({ visible: false, image: "" })
  const [loading, setLoading] = useState(false)
  const uploading = useRef(false)
  const uploadRef = useRef()

  const getFileList = () => {
    if (!value) {
      return []
    }

    return Array.isArray(value)
      ? value.map((img) => ({ uid: img, url: img }))
      : [{ uid: value, url: value }]
  }

  const handleUpload = (_, fileList) => {
    if (uploading.current) {
      return
    }

    uploading.current = true

    setLoading(true)
    return new Promise((resolve, reject) => {
      Promise.all(
        fileList?.map(async (file) => {
          return uploadImage(file)
            .then((data) => data)
            .catch((err) => {
              reject(err)
            })
        }),
      )
        .then((files) => {
          const newValues = multiple ? [...(value || []), ...files] : files?.[0]

          onChange && onChange(newValues)
          uploading.current = false
          resolve(newValues)
        })
        .finally(() => setLoading(false))
    })
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreview({ visible: true, image: file.url || file.preview })
  }

  const handleRemove = (file) => {
    const newValues = value.filter((url) => url !== file?.url)

    onChange && onChange(newValues)
  }

  return (
    <Row gutter={[16, 16]}>
      <Col>
        <Upload
          fileList={getFileList()}
          listType={"picture-card"}
          beforeUpload={handleUpload}
          onPreview={handlePreview}
          multiple={multiple}
          onRemove={(file) => multiple && handleRemove(file)}
          className={`custom-uploader ${!multiple ? "[&_button]:hidden" : ""} ${readOnly ? "[&_button]:!hidden" : ""}`}
        >
          <Button ref={uploadRef} style={{ display: "none" }}></Button>
        </Upload>
        <Modal
          open={preview.visible}
          title={"Ảnh"}
          footer={null}
          centered
          width={800}
          onCancel={() => setPreview({ visible: false, image: "" })}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={preview.image}
          />
        </Modal>
      </Col>
      <Col className="flex items-end pb-[16px]">
        <div className={`${readOnly ? "hidden" : ""}`}>
          <Button
            disabled={disabled}
            loading={loading}
            onClick={() => uploadRef.current?.click()}
            icon={<UploadOutlined />}
          >
            Tải ảnh
          </Button>
          {description && (
            <div className={`mt-[8px] ${value?.length > 0 ? "mx-3" : ""}`}>{description}</div>
          )}
        </div>
      </Col>
    </Row>
  )
}
