import React, { useEffect } from "react"
import { Row, Col, Form, Input, Select, Divider, Button, Popconfirm } from "antd"
import { PROJECT_TYPE } from "~/constants"
import { RangePicker, Upload, UserSelect } from "~/components"
import { isEmpty, isEqual } from "lodash"
import { useAuth } from "~/hooks"

const ProjectForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const { isAdmin } = useAuth()
  const [form] = Form.useForm()

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = (values) => {
    const { rangeTime, creator, ...rest } = values
    onSubmit({
      ...rest,
      ...(creator && { creatorId: creator?.value }),
      ...(rangeTime?.[0] && rangeTime?.[1] && { startDate: rangeTime[0], endDate: rangeTime[1] }),
    })
  }

  return (
    <div>
      <Form
        labelCol={{ span: 6 }}
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          {isAdmin && (
            <Col xs={24} lg={24}>
              <Form.Item
                name={"creator"}
                label={"Chủ dự án"}
                rules={[{ message: "Chủ dự án là bắt buộc", required: true }]}
              >
                <UserSelect onChange={(creator) => form.setFieldsValue({ creator })} />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Name"}
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input placeholder={"Please input name"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Project Image"}
              name="imageUrl"
              rules={[{ required: true, message: "Image is required !" }]}
            >
              <Upload description={"Upload an image of 800 x 800 pixels."} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Type"}
              name="projectType"
              rules={[{ required: true, message: "Project type is required" }]}
            >
              <Select
                options={Object.keys(PROJECT_TYPE).map((i) => ({
                  label: PROJECT_TYPE[i],
                  value: i,
                }))}
                placeholder={"Please input type"}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Location"} name="location">
              <Input placeholder={"Please input location"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item label={"Area"} name="area">
              <Input placeholder={"Please input area"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              name="rangeTime"
              label="Time"
              // rules: [
              //   {
              //     type: "array",
              //     required: true,
              //     message: "Please select time!",
              //   },
              // ],
            >
              <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              Submit
            </Button>
            <Button
              onClick={onCancel}
              disabled={loading}
              type={"outline"}
              style={{ marginLeft: 16 }}
            >
              Cancel
            </Button>
          </div>
          {id && (
            <div>
              <Popconfirm title={"Are you sure to remove this project ?"} onConfirm={onRemove}>
                <Button loading={loading} type={"danger"} style={{ marginLeft: 16 }}>
                  Remove project
                </Button>
              </Popconfirm>
            </div>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default ProjectForm
