import React, { useCallback, useEffect } from "react"
import { Row, Col, Form, Input, Select, Divider, Button, Popconfirm } from "antd"
import * as dayjs from "dayjs"
import { EMAIL_REGEX, PHONE_REGEX, ROLE } from "~/constants"
import { useHistory } from "react-router-dom"
import { useAuth } from "~/hooks"
import { debounce } from "lodash"

const UserForm = ({ id, loading, initialValues, onSubmit = () => {}, onRemove = () => {} }) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const { isAdmin } = useAuth()
  const isEditable = id && id !== "create"

  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  const onFinish = useCallback(
    debounce((values) => {
      const { confirmPassword, ...rest } = values
      onSubmit({
        ...rest,
      })
    }, 300),
    [],
  )

  return (
    <div>
      <Form
        layout={"vertical"}
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Email"}
              name="email"
              rules={[
                { required: true, message: "Email là bắt buộc!" },
                {
                  pattern: EMAIL_REGEX,
                  message: "Email không hợp lệ!",
                },
              ]}
            >
              <Input disabled={id && id !== "create"} placeholder={"Nhập vào email"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"SDT"}
              name="phone"
              rules={[
                { required: true, message: "SDT là bắt buộc!" },
                {
                  pattern: PHONE_REGEX,
                  message: "SĐT không hợp lệ!",
                },
              ]}
            >
              <Input placeholder={"Nhập vào SDT"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Họ"}
              name="lastName"
              rules={[{ required: true, message: "Họ là bắt buộc" }]}
            >
              <Input placeholder={"Nhập vào họ"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Tên"}
              name="firstName"
              rules={[{ required: true, message: "Tên là bắt buộc" }]}
            >
              <Input placeholder={"Nhập vào tên"} />
            </Form.Item>
          </Col>
          {isAdmin && (
            <Col xs={24} lg={12}>
              <Form.Item noStyle shouldUpdate>
                {() => (
                  <Form.Item
                    label={"Role"}
                    name="role"
                    rules={[{ required: true, message: "Vai trò là bắt buộc" }]}
                  >
                    <Select
                      options={Object.keys(ROLE).map((i) => ({ label: ROLE[i], value: i }))}
                      placeholder={"Chọn vai trò"}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          )}
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Password"}
              name="password"
              rules={[
                !isEditable && {
                  required: true,
                  message: "Mật khẩu là bắt buộc",
                },
                {
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                  message:
                    "Mật khẩu phải có ít nhất 8 ký tự và phải có ít nhất một chữ cái và một số",
                },
              ].filter((i) => i)}
            >
              <Input.Password className="w-full" placeholder="Vui lòng nhập vào mật khẩu" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Nhập lại mật khẩu"}
              name="confirmPassword"
              rules={[
                !isEditable && {
                  required: true,
                  message: "Mật khẩu là bắt buộc",
                },
                {
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                  message:
                    "Mật khẩu phải có ít nhất 8 ký tự và phải có ít nhất một chữ cái và một số",
                },
                {
                  validator: (_, value) => {
                    if (value !== form.getFieldValue("password")) {
                      return Promise.reject("Mật khẩu không khớp")
                    }
                    return Promise.resolve()
                  },
                },
              ].filter((i) => i)}
            >
              <Input.Password className="w-full" placeholder="Vui lòng nhập vào mật khẩu" />
            </Form.Item>
          </Col>
          {isEditable && (
            <>
              <Col xs={24} lg={12}>
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <Form.Item label={"Ngày tạo"} name="createdAt">
                      {form.getFieldValue("createdAt") &&
                        dayjs(form.getFieldValue("createdAt")).format("DD/MM/YYYY")}
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <Form.Item label={"Cập nhật cuối vào lúc"} name="updatedAt">
                      {form.getFieldValue("updatedAt") &&
                        dayjs(form.getFieldValue("updatedAt")).format("DD/MM/YYYY")}
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              {id ? "Lưu" : "Thêm"}
            </Button>
            <Button
              onClick={history?.goBack}
              disabled={loading}
              type={"outline"}
              style={{ marginLeft: 16 }}
            >
              Hủy
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  )
}

export default UserForm
