import React, { useCallback, useEffect, useState } from "react"
import { Input } from "antd"
import debounce from "lodash/debounce"

const DebounceInput = ({ value: defaultValue, onChange, debounceTime = 500, ...restProps }) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue)
    }
    // eslint-disable-next-line
  }, [defaultValue])

  const debounceChange = useCallback(debounce(onChange, debounceTime), [])

  const handleChange = (e) => {
    setValue(e?.target?.value)
    debounceChange(e?.target?.value)
  }

  return <Input value={value} onChange={handleChange} {...restProps} />
}

export default DebounceInput
