import { Button, Col, message, Row } from "antd"
import React, { useState } from "react"
import StageForm from "~/components/StageForm"
import StageSelect from "~/components/StageSelect"
import { createProcedureStage, createStage, updateProcedure } from "~/services/apis"

function AddStageModal({ procedure, onFinish }) {
  const [newStageLoading, setNewStageLoading] = useState(false)
  const [selectedStage, setSelectedStage] = useState(null)
  const procedureId = procedure?.id

  const handleSubmitNewStage = async (values) => {
    try {
      setNewStageLoading(true)

      const response = await createStage({
        ...values,
      })

      if (response?.createStage) {
        const newProcedureStage = await createProcedureStage({
          stageId: response?.createStage?.id,
          procedureId,
        })

        if (newProcedureStage) {
          onFinish?.()
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setNewStageLoading(false)
    }
  }

  const handleAddExistingStage = async () => {
    if (!selectedStage) {
      message.error("Vui lòng chọn mẫu giai đoạn!")
      return
    }

    try {
      setNewStageLoading(true)
      const response = await updateProcedure(procedureId, {
        stageIds: [...procedure?.procedureStages?.map((pS) => pS?.stageId), selectedStage?.value],
      })

      if (response?.updateProcedure) {
        onFinish?.()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setNewStageLoading(false)
    }
  }

  return (
    <div className="lg:mx-5 lg:mt-5">
      {/* <div className="mb-2 text-[16px] font-medium">Chọn mẫu giai đoạn</div>
      <Row gutter={10}>
        <Col span={18}>
          <StageSelect
            size="large"
            className={"w-full"}
            value={selectedStage}
            onChange={setSelectedStage}
            excludes={[...procedure?.procedureStages?.map((pS) => pS?.stageId)]}
          />
        </Col>
        <Col span={6}>
          <Button
            loading={newStageLoading}
            onClick={() => handleAddExistingStage()}
            block
            size="large"
            type="primary"
          >
            Thêm
          </Button>
        </Col>
      </Row>

      <div className="my-5 text-gray-500">Hoặc</div> */}
      {/* <div className="mb-2 text-[16px] font-medium">Thêm giai đoạn mới</div> */}

      <StageForm loading={newStageLoading} onSubmit={handleSubmitNewStage} />
    </div>
  )
}

export default AddStageModal
