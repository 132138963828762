import React, { useCallback, useState } from "react"
import { Drawer } from "antd"
import { DeviceFunctionForm } from "~/components"

const useDeviceFunction = ({
  deviceFunction,
  loading = false,
  width = "1200px",
  onClose,
  onSubmit,
  onRemove,
} = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const initialValues = {
    ...deviceFunction,
    type: deviceFunction?.type || "property",
    parameters: deviceFunction?.parameters || [{ dataType: "String" }],
  }

  const DeviceFunctionDrawer = useCallback(() => {
    return (
      (<Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Function"}
        open={visible}
        onClose={onCancel}
      >
        <DeviceFunctionForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          onRemove={onRemove}
          onCancel={onCancel}
          loading={loading}
        />
      </Drawer>)
    );
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    DeviceFunctionDrawer,
  }
}

export default useDeviceFunction
