import { Button, Col, Divider, Form, Input, Popconfirm, Row } from "antd"
import React, { useEffect } from "react"
import { DeviceProfileSelect, ProjectSelect, UserSelect, Upload, GroupSelect } from "~/components"

const DeviceForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onConfirmDelete = () => {},
}) => {
  const isEditable = id && id !== "create"
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  const handleSubmit = (values) => {
    values?.deviceId?.length === 0 && delete values.deviceId
    onSubmit(values)
  }

  return (
    <>
      <Form
        labelCol={{ span: 6 }}
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={handleSubmit}
      >
        <Row gutter={20}>
          {isEditable && (
            <Col xs={24} lg={24}>
              <Form.Item label={"Thing ID"} name="deviceId">
                <Input readOnly />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Device Name"}
              name="name"
              rules={[{ required: true, message: "Device name is required" }]}
            >
              <Input placeholder={"Please input Device name"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Device Serial"}
              name="deviceSerial"
              rules={[{ required: true, message: "Device Serial is required" }]}
            >
              <Input placeholder={"Please input Device serial"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Product Image"}
              name="imageUrl"
              rules={[{ required: true, message: "Image is required !" }]}
            >
              <Upload description={"Upload an image of 800 x 800 pixels."} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item label={"Owner"} name="creator">
              <UserSelect
                excludes={[id]}
                onChange={(creator) => form.setFieldsValue({ creator, project: null, group: null })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Product"}
              name="deviceProfile"
              rules={[{ required: true, message: "Product is required !" }]}
            >
              <DeviceProfileSelect excludes={[id]} />
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prev, cur) =>
              prev.projectId !== cur.projectId || prev.creatorId !== cur.creatorId
            }
          >
            {() => (
              <>
                <Col xs={24} lg={24}>
                  <Form.Item label={"Project"} name="project">
                    <ProjectSelect
                      creatorId={form.getFieldValue("creator")?.value || undefined}
                      excludes={[id]}
                      onChange={(project) => form.setFieldsValue({ project, group: null })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item label={"Zone"} name="group">
                    <GroupSelect
                      excludes={[id]}
                      projectId={form.getFieldValue("project")?.value || undefined}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.Item>
          <Form.Item name={"functions"} hidden={true} />
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="end">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              {id ? "Save" : "Create"}
            </Button>
          </div>
          {id && (
            <div>
              <Popconfirm
                title={"Are you sure to delete this device ?"}
                onConfirm={onConfirmDelete}
              >
                <Button loading={loading} type={"danger"} style={{ marginLeft: 16 }}>
                  Remove device
                </Button>
              </Popconfirm>
            </div>
          )}
        </Row>
      </Form>
    </>
  )
}

export default DeviceForm
