import { Col, Row } from "antd"
import React from "react"
import "./styles.less"

const MOIT_NOTI_LINK = "http://online.gov.vn/Home/WebDetails/87764?AspxAutoDetectCookieSupport=1"
const APP_LINK = "https://apps.apple.com/vn/app/demeter/id1621694610"

export const LoginFooter = () => {
  return (
    <Row className={"footer"} style={{ background: "white" }}>
      <Col span={24} className={"container"}>
        <div className={"footer__topline"} />
        <Row className={"footer__content"} gutter={0} justify={"space-between"}>
          <Col flex={2} className={"footer__content__description"}>
            <div className={"description__group"}>
              <img alt="" src={"/assets/png/footer-company.png"} />
              <span>CÔNG TY TNHH CÔNG NGHỆ VÀ THƯƠNG MẠI UFO</span>
            </div>
            <div className={"description__group"}>
              <img alt="" src={"/assets/png/footer-location.png"} />
              <span>114 Đường 9A, Bình Hưng, Bình Chánh, TP.HCM</span>
            </div>
            <div className={"description__group"} style={{ maxWidth: 320 }}>
              <img alt="" src={"/assets/png/foodmap-cert.png"} />
              <span>
                Giấy chứng nhận Đăng ký Kinh doanh số 0314592854 do Sở Kế hoạch và Đầu tư Thành phố
                Hồ Chí Minh cấp ngày 24/08/2017
              </span>
            </div>
          </Col>
          <Col flex={1} className={"footer__content__description"}>
            <div className={"description__group"}>
              <img alt="" src={"/assets/png/footer-email.png"} />
              <span>tung.pham@demeter.vn</span>
            </div>
            <div className={"description__group"}>
              <img alt="" src={"/assets/png/footer-phone.png"} />
              <span>Hotline: (+84) 34 3956 769</span>
            </div>
          </Col>
          <Col flex={1} className={"footer__content__description"}>
            <div className={"description__group"}>
              <a href="/">Điều khoản và quy định chung</a>
            </div>
            <div className={"description__group"}>
              <a href="/">Hình thức thanh toán</a>
            </div>
            <div className={"description__group"}>
              <a href="/">Bảo mật thông tin</a>
            </div>
          </Col>
          <Col flex={1} className={"footer__content__description "}>
            <div className={"description__group logo"}>
              <a href={APP_LINK} target="blank">
                <img alt="" src={"/assets/png/footer-qr-app.png"} style={{ maxHeight: 136 }} />
              </a>
            </div>
          </Col>
          <Col flex={1} className={"footer__content__description"} style={{ marginTop: "1.5rem" }}>
            <div className={"description__group logo"}>
              <a href={MOIT_NOTI_LINK} target="blank">
                <img alt="" src={"/assets/png/footer-moit-noti.png"} />
              </a>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default LoginFooter
