import React from "react"
import { Select } from "antd"

const CurrentProcedureStageSelect = ({
  className,
  multiple = false,
  value = null,
  onChange = () => {},
  excludes = [],
  allowClear = true,
  procedureStages,
  ...props
}) => {
  return (
    <Select
      labelInValue
      value={value}
      showSearch={true}
      notFoundContent={"Không tìm thấy giai đoạn"}
      mode={multiple && "multiple"}
      options={procedureStages}
      onChange={onChange}
      allowClear={allowClear}
      filterOption={false}
      dropdownRender={(menu) => <>{menu}</>}
      onClear={() => onChange(null)}
      className={className}
      placeholder="Chọn giai đoạn"
      {...props}
    />
  )
}

export default CurrentProcedureStageSelect
