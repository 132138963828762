import { Col, Row, Divider } from "antd"
import React from "react"

const DataTypeDescription = () => {
  return (
    <Row>
      <Col span={4}>
        <span style={{ fontWeight: 600, fontSize: 16, color: "var(--black)" }}>Value:</span>
      </Col>
      <Col span={20}>
        Take the temperature which ranges from 1°C to 100°C as an example. If the reported data is
        36.6°C, The definition is as follows:
        <ul>
          <li>Data type: Value</li>
          <li>Value Range: 1 to 1,000</li>
          <li>
            Pitch: specifies the difference between adjacent values. For example, if the pitch is 1,
            the valid values will be 0, 1, 2… If the pitch is 3, the valid values will be 0, 3, 6…
          </li>
          <li>
            Scale: is used to transform the data of an integer to a decimal, such as transforming 1
            to 0.1. In practice, the functions on the devices control the scale. It is only for
            parameter definition here.
          </li>
          <li>Unit: °C, %, ....</li>
        </ul>
      </Col>
      <Divider />
      <Col span={4}>
        <span style={{ fontWeight: 600, fontSize: 16, color: "var(--black)" }}>String:</span>
      </Col>
      <Col span={20}>
        Take the URL of the image as an example. The definition is as follows:
        <ul>
          <li>Data type: String</li>
        </ul>
      </Col>
      <Divider />
      <Col span={4}>
        <span style={{ fontWeight: 600, fontSize: 16, color: "var(--black)" }}>Date:</span>
      </Col>
      <Col span={20}>
        Take the product update time as an example. The device data is reported and parsed by the
        timestamp. The definition is as follows:
        <ul>
          <li>Data type: Date</li>
        </ul>
      </Col>
      <Divider />
      <Col span={4}>
        <span style={{ fontWeight: 600, fontSize: 16, color: "var(--black)" }}>Bool:</span>
      </Col>
      <Col span={20}>
        Take the switch as an example. The switch has two statuses: on and off. The definition is as
        follows:
        <ul>
          <li>Data type: Bool</li>
          <li>True: On</li>
          <li>False: Off</li>
        </ul>
      </Col>
      <Divider />
      <Col span={4}>
        <span style={{ fontWeight: 600, fontSize: 16, color: "var(--black)" }}>Enum:</span>
      </Col>
      <Col span={20}>
        Take the run mode as an example. It includes Performance mode, Balance mode and Power saving
        mode. The definition is as follows:
        <ul>
          <li>Data Type: Enum</li>
          <li>Enum values: Performance/Power Saving/Balance</li>
        </ul>
      </Col>
      <Divider />
      <Col span={4}>
        <span style={{ fontWeight: 600, fontSize: 16, color: "var(--black)" }}>Raw:</span>
      </Col>
      <Col span={20}>
        Take the binary code as an example. It cannot be transmitted in plaintext.
        <ul>
          <li>Data type: RAW</li>
          <li>The platform directly transmits the data to the device without any processing.</li>
        </ul>
      </Col>
    </Row>
  )
}

export default DataTypeDescription
