import React, { useCallback, useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button, Popconfirm, AutoComplete, InputNumber } from "antd"
import { Upload, UserSelect } from "~/components"
import { isEmpty, isEqual } from "lodash"
import { PRODUCT_UNIT } from "~/constants"
import CustomEditor from "../Editor"
import { useAuth } from "~/hooks"
import { debounce } from "lodash"

const ProductForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const { user } = useAuth()
  const [form] = Form.useForm()

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = useCallback(
    debounce((values) => {
      const { ...rest } = values
      onSubmit({
        ...rest,
      })
    }, 300),
    [],
  )

  return (
    <div>
      <Form
        colon={false}
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          {id && (
            <Col xs={24} lg={24}>
              <Form.Item name={"creator"} label={"Người tạo"}>
                <UserSelect onChange={(creator) => form.setFieldsValue({ creator })} />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Hình ảnh"}
              name="imageUrls"
              rules={[{ required: true, message: "Hình ảnh là bắt buộc !" }]}
            >
              <Upload description={"Tải ảnh sản phẩm (800 x 800)"} multiple />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Tên sản phẩm"}
              name="name"
              rules={[{ required: true, message: "Tên sản phẩm là bắt buộc" }]}
            >
              <Input placeholder={"Nhập tên sản phẩm"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={"Giống sản phẩm"}
              name="kind"
              rules={[{ required: true, message: "Giống sản phẩm là bắt buộc!" }]}
            >
              <Input placeholder={"Nhập tên giống sản phẩm"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label={"Quy cách đóng gói"} name="packagingUnit">
              <AutoComplete options={PRODUCT_UNIT.map((i) => ({ label: i, value: i }))}>
                <Input placeholder={"Nhập quy cách đóng gói"} />
              </AutoComplete>
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label={"Khối lượng tịnh"} name="netWeight">
              <Input placeholder={"Nhập khối lượng tịnh"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label={"Đơn vị"} name="unit">
              <AutoComplete options={PRODUCT_UNIT.map((i) => ({ label: i, value: i }))}>
                <Input placeholder={"Nhập đơn vị"} />
              </AutoComplete>
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label={"Barcode (GS1)"} name="barCode">
              <Input placeholder={"Nhập mã vạch"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label={"Liên kết web"} name="url">
              <Input placeholder={"Nhập liên kết web"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Giới thiệu sản phẩm"} name="description">
              <CustomEditor />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Hướng dẫn sử dụng"} name="userManual">
              <CustomEditor />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              Lưu
            </Button>
            <Button
              onClick={onCancel}
              disabled={loading}
              type={"outline"}
              style={{ marginLeft: 16 }}
            >
              Hủy
            </Button>
          </div>
          {id && user?.role !== "employee" && (
            <div>
              <Popconfirm title={"Bạn có chắc chắn muốn xóa sản phẩm này ?"} onConfirm={onRemove}>
                <Button loading={loading} type={"danger"} style={{ marginLeft: 16 }}>
                  Xóa sản phẩm
                </Button>
              </Popconfirm>
            </div>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default ProductForm
