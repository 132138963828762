import React, { useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button, Popconfirm } from "antd"
import { RangePicker, Upload } from "~/components"
import { Link } from "react-router-dom"
import { isEmpty, isEqual } from "lodash"

const rangeConfig = {
  rules: [
    {
      type: "array",
      required: true,
      message: "Please select time!",
    },
  ],
}

const GroupForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = (values) => {
    const { rangeTime, ...rest } = values
    onSubmit({
      ...rest,
      startDate: rangeTime[0],
      endDate: rangeTime[1],
    })
  }

  return (
    <div>
      <Form
        labelCol={{ span: 6 }}
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          {id && (
            <Col xs={24} lg={24}>
              <Form.Item noStyle shouldUpdate>
                {() => {
                  const creator = form
                    .getFieldValue("users")
                    ?.find((user) => user?.id === form.getFieldValue("creatorId"))

                  return (
                    <Form.Item name={"creator"} label={"Creator"}>
                      <div>
                        <Link to={`/users/${creator?.id}`}>
                          {creator?.firstName} {creator?.lastName}
                        </Link>
                      </div>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
          )}
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Name"}
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input placeholder={"Please input name"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Group Image"}
              name="imageUrl"
              rules={[{ required: true, message: "Image is required !" }]}
            >
              <Upload description={"Upload an image of 800 x 800 pixels."} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item label={"Location"} name="location">
              <Input placeholder={"Please input location"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item label={"Area"} name="area">
              <Input placeholder={"Please input area"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item name="rangeTime" label="Time" {...rangeConfig}>
              <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              Submit
            </Button>
            <Button
              onClick={onCancel}
              disabled={loading}
              type={"outline"}
              style={{ marginLeft: 16 }}
            >
              Cancel
            </Button>
          </div>
          {id && (
            <div>
              <Popconfirm title={"Are you sure to remove this group ?"} onConfirm={onRemove}>
                <Button loading={loading} type={"danger"} style={{ marginLeft: 16 }}>
                  Remove group
                </Button>
              </Popconfirm>
            </div>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default GroupForm
