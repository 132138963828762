import { lazy } from "react"
import CamerasPage from "./pages/cameras"
import CameraPage from "./pages/cameras/[id]"
import ProductsPage from "./pages/products"
import ProductDetailPage from "./pages/products/[id]"
import ProceduresPage from "./pages/procedures"
import StagesPage from "./pages/stages"
import TasksPage from "./pages/tasks"
import TraceabilityPage from "./pages/traceabilities"
import ProcedurePage from "./pages/procedures/[id]"
import EnterprisesPage from "./pages/enterprises"
import SuppliesPage from "./pages/supplies"
import FarmDiariesPage from "./pages/farm-diaries"
//
const LoginPage = lazy(() => import("./pages/login"))
// const RegisterPage = lazy(() => import("./pages/register"))
const Page404 = lazy(() => import("./pages/notFound"))
//
const DashboardPage = lazy(() => import("./pages/dashboard"))
//User
const UsersPage = lazy(() => import("./pages/users"))
const UserPage = lazy(() => import("./pages/users/[id]"))
// Project
const ProjectsPage = lazy(() => import("./pages/projects"))
const ProjectPage = lazy(() => import("./pages/projects/[id]"))
// Category
const CategoriesPage = lazy(() => import("./pages/categories"))
const CategoryPage = lazy(() => import("./pages/categories/[id]"))
//Device
const DevicesPage = lazy(() => import("./pages/devices"))
const DevicePage = lazy(() => import("./pages/devices/[id]"))
//DeviceProfile
const DeviceProfilesPage = lazy(() => import("./pages/device-profiles"))
const DeviceProfilePage = lazy(() => import("./pages/device-profiles/[id]"))
//DeviceFunction
const DeviceFunctionsPage = lazy(() => import("./pages/device-functions"))
const DeviceFunctionPage = lazy(() => import("./pages/device-functions/[id]"))
//Employee Management
const EmployeeManagementPage = lazy(() => import("./pages/employee-management"))

//Term and Policy
const TermOfService = lazy(() => import("./pages/term-of-service"))
const PrivatePolicy = lazy(() => import("./pages/private-policy"))

const routes = [
  {
    path: "/",
    component: LoginPage,
    exact: true,
    isPublic: true,
  },
  // {
  //   path: "/register",
  //   component: RegisterPage,
  //   exact: true,
  //   isPublic: true,
  // },
  {
    path: "/dashboard",
    component: DashboardPage,
    exact: true,
  },
  {
    path: "/products",
    component: ProductsPage,
    exact: true,
  },
  {
    path: "/products/:id",
    component: ProductDetailPage,
  },
  {
    path: "/users",
    component: UsersPage,
    exact: true,
  },
  {
    path: "/users/:id",
    component: UserPage,
  },
  {
    path: "/projects",
    component: ProjectsPage,
    exact: true,
  },
  {
    path: "/projects/:id",
    component: ProjectPage,
  },
  {
    path: "/categories",
    component: CategoriesPage,
    exact: true,
  },
  {
    path: "/categories/:id",
    component: CategoryPage,
  },
  {
    path: "/devices",
    component: DevicesPage,
    exact: true,
  },
  {
    path: "/devices/:id",
    component: DevicePage,
  },
  {
    path: "/device-profiles",
    component: DeviceProfilesPage,
    exact: true,
  },
  {
    path: "/device-profiles/:id",
    component: DeviceProfilePage,
  },
  {
    path: "/device-functions",
    component: DeviceFunctionsPage,
    exact: true,
  },
  {
    path: "/device-functions/:id",
    component: DeviceFunctionPage,
  },
  {
    path: "/cameras",
    component: CamerasPage,
    exact: true,
  },
  {
    path: "/cameras/:id",
    component: CameraPage,
  },
  {
    path: "/term-of-service",
    component: TermOfService,
    exact: true,
    isPublic: true,
  },
  {
    path: "/private-policy",
    component: PrivatePolicy,
    exact: true,
    isPublic: true,
  },
  {
    path: "/404",
    component: Page404,
    exact: true,
    isPublic: true,
  },
  {
    path: "/diaries/procedures",
    component: ProceduresPage,
    exact: true,
  },
  {
    path: "/diaries/procedures/:id",
    component: ProcedurePage,
  },
  {
    path: "/diaries/stages",
    component: StagesPage,
    exact: true,
  },
  {
    path: "/diaries/tasks",
    component: TasksPage,
    exact: true,
  },
  {
    path: "/farm-diaries",
    component: FarmDiariesPage,
    exact: true,
  },
  {
    path: "/diaries/enterprises",
    component: EnterprisesPage,
    exact: true,
  },
  {
    path: "/traceability",
    component: TraceabilityPage,
    exact: true,
  },
  {
    path: "/supplies",
    component: SuppliesPage,
    exact: true,
  },
  {
    path: "/employee-management",
    component: EmployeeManagementPage,
    exact: true,
  },
]

export { routes }
