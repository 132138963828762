import React, { useState, useCallback } from "react"
import { Drawer } from "antd"
import { ProductForm } from "~/components"

const useProduct = ({
  product,
  loading = false,
  width = "80%",
  onClose,
  onSubmit,
  onRemove,
} = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const ProductDrawer = useCallback(() => {
    return (
      <Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Thông tin sản phẩm"}
        open={visible}
        onClose={onCancel}
      >
        <ProductForm
          id={product?.id}
          initialValues={{
            ...product,
          }}
          onSubmit={onSubmit}
          onRemove={onRemove}
          onCancel={onCancel}
          loading={loading}
        />
      </Drawer>
    )
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    ProductDrawer,
  }
}

export default useProduct
