import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import {
  Col,
  Row,
  Pagination,
  Space,
  message,
  Button,
  Modal,
  Upload,
  Image,
  DatePicker,
} from "antd"
import React, { useEffect, useRef, useState } from "react"
import { useSetState } from "react-use"
import { ActionImage } from "~/components"
import { createSnapshot, getSnapshots, removeSnapshot, uploadImage } from "~/services/apis"
import dayjs from "dayjs"

const DEFAULT_PARAMS = {
  search: "",
  limit: 30,
  page: 1,
}

export default function SnapshotTab({ deviceId }) {
  const [snapshots, setSnapshots] = useState(null)
  const [loading, setLoading] = useState(false)
  const [creatingSnapshot, setCreatingSnapshot] = useState(false)
  const [meta, setMeta] = useState({})
  const [params, setParams] = useSetState({ ...DEFAULT_PARAMS })
  const [showAddPhoto, setShowAddPhoto] = useState(false)
  const [uploadFiles, setUploadFiles] = useState([])
  const uploading = useRef(null)

  const fetchSnapshots = () => {
    return getSnapshots(params, { snapshotableId: deviceId }).then(({ data, headers }) => {
      setSnapshots(data.snapshots)
      setMeta((headers && JSON.parse(headers.get("meta"))) || {})
    })
  }

  useEffect(() => {
    fetchSnapshots()
    // eslint-disable-next-line
  }, [deviceId, params])

  const onRemoveSnapshot = (snapshot) => {
    return removeSnapshot(snapshot?.id).then(() => {
      message.success("Remove snapshot success !")
      fetchSnapshots()
    })
  }

  const handleUpload = (_, fileList) => {
    if (uploading.current) {
      return
    }

    uploading.current = true

    setLoading(true)
    return new Promise((resolve, reject) => {
      Promise.all(
        fileList?.map(async (snapshot) => {
          return uploadImage(snapshot, deviceId)
            .then((data) => data)
            .catch((err) => {
              reject(err)
            })
        }),
      )
        .then((files) => {
          uploading.current = false
          setUploadFiles([
            ...uploadFiles,
            ...files.map((file) => ({ url: file, capturedAt: dayjs().toISOString() })),
          ])
          resolve()
        })
        .finally(() => setLoading(false))
    })
  }

  const onCreatingSnapshots = () => {
    setCreatingSnapshot(true)
    return new Promise((resolve, reject) => {
      Promise.all(
        uploadFiles?.map(async (file) => {
          return createSnapshot({ ...file, snapshotableType: "camera", snapshotableId: deviceId })
            .then((data) => data)
            .catch((err) => {
              reject(err)
            })
            .finally(() => setLoading(false))
        }),
      ).then(() => {
        resolve()
        setCreatingSnapshot(false)
        setShowAddPhoto(false)
        setUploadFiles([])
        fetchSnapshots()
      })
    })
  }

  return (
    <>
      <Row>
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => setShowAddPhoto(true)}
            loading={loading}
            icon={<PlusOutlined />}
            style={{ marginBottom: 8 }}
          >
            Add photo(s)
          </Button>
          <Modal
            width={"80%"}
            style={{ maxWidth: 1000 }}
            title={"  (s)"}
            open={showAddPhoto}
            onCancel={() => setShowAddPhoto(false)}
            onOk={onCreatingSnapshots}
            confirmLoading={creatingSnapshot}
            destroyOnClose
          >
            {/* <DatePicker inputReadonly showTime /> */}
            <Upload
              className={"add-photo-upload"}
              beforeUpload={handleUpload}
              fileList={[]}
              multiple
            >
              <Button loading={loading}>Choose image(s)</Button>
            </Upload>
            <Row gutter={[12, 12]} style={{ marginTop: 10 }}>
              {uploadFiles?.map((file, index) => (
                <>
                  <Col xl={4} xs={8}>
                    <div style={{ width: "100%", borderRadius: 5 }}>
                      <Image
                        src={file?.url}
                        width={"100%"}
                        style={{ aspectRatio: "16 / 9", objectFit: "cover" }}
                      />
                    </div>
                  </Col>
                  <Col xl={7} xs={15}>
                    <div style={{ fontWeight: 500, fontSize: 15 }}>Capture at</div>
                    <DatePicker
                      showTime
                      defaultValue={
                        console.log(dayjs(uploadFiles?.[index]?.capturedAt)) ||
                        dayjs(uploadFiles?.[index]?.capturedAt)
                      }
                      onChange={(date) => {
                        const newValues = [...uploadFiles]
                        newValues[index] = {
                          ...newValues[index],
                          capturedAt: date.toISOString(),
                        }
                        setUploadFiles(newValues)
                      }}
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={1}>
                    <DeleteOutlined
                      onClick={() => {
                        const newValues = [...uploadFiles].filter((i) => i?.url !== file?.url)
                        setUploadFiles(newValues)
                      }}
                    />
                  </Col>
                </>
              ))}
            </Row>
          </Modal>
        </Col>
      </Row>
      {!snapshots?.length && <div className="place-center">Device has 0 photo(s)</div>}
      {snapshots?.length ? (
        <Space
          direction={"vertical"}
          style={{ height: "100%", width: "100%", justifyContent: "space-between" }}
        >
          <Row gutter={[20, 12]} style={{ width: "100%" }}>
            {snapshots?.map((snapshot) => (
              <Col span={4} key={snapshot?.id}>
                <ActionImage
                  date={dayjs(snapshot?.capturedAt || snapshot?.createdAt).format(
                    "DD/MM/YYYY hh:mm:ss A",
                  )}
                  src={snapshot?.url}
                  style={{
                    width: "100%",
                    aspectRatio: "4 / 3",
                  }}
                  previewable
                  removable
                  onRemove={() => onRemoveSnapshot(snapshot)}
                />
              </Col>
            ))}
          </Row>
          <Row style={{ width: "100%", margin: "24px 0" }} justify={"center"}>
            <Pagination
              current={params?.page}
              total={meta?.totalDocs || 0}
              pageSizeOptions={[10, 20, 30, 50, 100]}
              showSizeChanger
              pageSize={params?.limit}
              onChange={(page, pageSize) => setParams({ page, limit: pageSize })}
            />
          </Row>
        </Space>
      ) : null}
    </>
  )
}
