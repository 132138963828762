import React, { useState, useEffect } from "react"
import { useSetState } from "react-use"
import { Row, Col, Input, Select, Modal, Button, Tag, Pagination, Spin } from "antd"
import { DEVICE_FUNCTION_TYPE, DEVICE_FUNCTION_DATA_TYPE } from "~/constants"
import { CloseOutlined, RightOutlined } from "@ant-design/icons"
import { getDeviceFunctions } from "~/services/apis"

import "./index.less"

const DEFAULT_PARAMS = {
  page: 1,
  limit: 10,
}

const AddFunction = ({ excludes = [], children, onChange, width = 1000 }) => {
  const [visible, setVisible] = useState(false)
  const [params, setParams] = useSetState({ ...DEFAULT_PARAMS })
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const [dataType, setDataType] = useState(null)
  const [addedFuncs, setAddedFuncs] = useState([])
  const [functions, setFunctions] = useState([])
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState(false)

  const appendData = async () => {
    setLoading(true)
    try {
      const { data, headers } = await getDeviceFunctions(params, { dataType })
      setMeta((headers && JSON.parse(headers.get("meta"))) || {})
      setFunctions([...(params.page === 1 ? [] : functions), ...(data?.deviceFunctions || [])])
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    appendData()
    // eslint-disable-next-line
  }, [params, dataType])

  const addFunction = (func) => {
    setAddedFuncs([...addedFuncs, func])
  }

  const renderFunction = ({ func, index, added = false } = {}) => {
    return (
      <Row onClick={() => !added && addFunction(func)}>
        <Col span={22}>
          <div>
            {func?.name} |&nbsp;&nbsp;
            <Tag color={"green"}>{DEVICE_FUNCTION_TYPE?.[func?.type]}</Tag>
          </div>
          <div>
            Identifier: {func?.key} &nbsp;&nbsp; Function Type:&nbsp;
            {func?.type === "property"
              ? DEVICE_FUNCTION_DATA_TYPE?.[func?.parameters?.[0]?.dataType]
              : "-"}
          </div>
        </Col>
        {!added && (
          <Col span={2} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
            <Button className={"transfer-button"}>
              <RightOutlined />
            </Button>
          </Col>
        )}
        {added && (
          <Col
            span={2}
            style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
            onClick={() => {
              const values = [...addedFuncs]
              values.splice(index, 1)
              setAddedFuncs(values)
            }}
          >
            <Button className={"transfer-button"}>
              <CloseOutlined />
            </Button>
          </Col>
        )}
      </Row>
    )
  }

  const onScroll = (e) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) {
      params.page < meta?.totalPages && setParams({ page: params.page + 1 })
    }
  }

  return (
    (<div>
      <div onClick={show}>{children}</div>
      <Modal
        destroyOnClose={true}
        title={"Add Function"}
        style={{ top: 50 }}
        width={width}
        open={visible}
        onCancel={() => {
          hide()
        }}
        onOk={() => {
          onChange && onChange([...addedFuncs])
          hide()
          setAddedFuncs([])
        }}
      >
        <Row className="transfer-table-container">
          <Row className={"table-head"}>
            <Col className="table-head__column" span={12}>
              Functions
            </Col>
            <Col className="table-head__column" span={12}>
              Selected Function({addedFuncs?.length})
            </Col>
          </Row>
          <Row className={"table-body"}>
            <Col className={"table-body__column"} span={12} onScroll={onScroll}>
              <Row className={"search-row"} gutter={16}>
                <Col span={12}>
                  <Input.Search
                    onSearch={(value) => setParams({ search: value, page: 1 })}
                    placeholder={"Enter function name"}
                  />
                </Col>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    onChange={setDataType}
                    allowClear={true}
                    placeholder="Data Type"
                    options={Object.keys(DEVICE_FUNCTION_DATA_TYPE).map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </Col>
              </Row>
              <div className={"function-row"}>
                {loading && (
                  <div className="place-center" style={{ height: 200 }}>
                    <Spin />
                  </div>
                )}
                {functions?.map(
                  (func, index) =>
                    ![...addedFuncs, ...excludes].find((existed) => existed.id === func.id) && (
                      <Col className={"function-item"} span={24} key={func?.id}>
                        {renderFunction({ func, index })}
                      </Col>
                    ),
                )}
              </div>
              {functions?.meta?.totalDocs > params?.limit && (
                <div className={"place-center table-body__pagination"}>
                  <Pagination
                    hideOnSinglePage={true}
                    defaultCurrent={1}
                    pageSize={params?.limit}
                    total={meta?.totalDocs}
                  />
                </div>
              )}
            </Col>
            <Col className={"table-body__column"} span={12}>
              <div className={"function-row"}>
                {addedFuncs?.map((func, index) => (
                  <Col className={"function-item"} span={24} key={index}>
                    {renderFunction({ func, index, added: true })}
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Row>
      </Modal>
    </div>)
  );
}

export default AddFunction
