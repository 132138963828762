import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { last } from "lodash"
import { getProduct, updateProduct } from "~/services/apis"
import { ProductForm } from "~/components"
import { message } from "antd"

function ProductDetailPage() {
  const { pathname } = useLocation()
  const [product, setProduct] = useState(null)
  const [loading, setLoading] = useState(false)
  const id = last(pathname.split("/"))

  const fetchProduct = async () => {
    const result = await getProduct(id)

    if (result?.product) {
      setProduct(result.product)
    }
  }

  useEffect(() => {
    fetchProduct()
  }, [id])

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const result = await updateProduct(id, values)

      if (result?.updateProduct) {
        message.success("Cập nhật sản phẩm thành công !")
        fetchProduct()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <ProductForm loading={loading} initialValues={product} onSubmit={onSubmit} />
    </div>
  )
}

export default ProductDetailPage
