import React, { useState, useCallback, useMemo } from "react"
import { Drawer } from "antd"
import { TaskForm } from "~/components"

const useTask = ({
  task,
  readOnly = false,
  loading = false,
  width = "60%",
  onClose,
  onSubmit,
  onRemove,
} = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const initialValues = useMemo(() => {
    return {
      ...task,
    }
  }, [task])

  const TaskDrawer = useCallback(() => {
    return (
      <Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Công việc"}
        open={visible}
        onClose={onCancel}
      >
        <TaskForm
          id={task?.id}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onRemove={onRemove}
          onCancel={onCancel}
          loading={loading}
          readOnly={readOnly}
        />
      </Drawer>
    )
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    TaskDrawer,
  }
}

export default useTask
