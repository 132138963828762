import React, { useEffect, useState } from "react"
import { Select, Spin } from "antd"
import { useSetState } from "react-use"
import { debounce } from "lodash"
import { getProjects } from "~/services/apis"

const DEFAULT_PARAMS = {
  limit: 10,
  page: 1,
  search: "",
}

const ProjectSelect = ({
  creatorId = undefined,
  multiple = false,
  value = null,
  size = "default",
  onChange = () => {},
  excludes = [],
  className,
  showSearch = true,
  placeholder = "Chọn dự án",
  ...props
}) => {
  const [params, setParams] = useSetState({ ...DEFAULT_PARAMS })
  const [projects, setProjects] = useState([])
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState(false)

  const appendData = async () => {
    setLoading(true)
    try {
      const { data, headers } = await getProjects(params, { creatorId })
      setMeta((headers && JSON.parse(headers.get("meta"))) || {})
      setProjects([
        ...(params.page === 1 ? [] : projects),
        ...(data?.projects || [])
          ?.filter((proj) => !excludes.includes(proj?.id))
          ?.map((proj) => {
            // const creator = proj?.creator || proj?.users?.find((usr) => usr.id === proj?.creatorId)

            return {
              label: `${proj?.name}`,
              value: proj?.id,
            }
          }),
      ])
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    appendData()
    // eslint-disable-next-line
  }, [params, creatorId])

  const onScroll = (e) => {
    if (
      !loading &&
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
    ) {
      params.page < meta?.totalPages && setParams({ page: params.page + 1 })
    }
  }

  return (
    <Select
      labelInValue
      value={value}
      showSearch={showSearch}
      notFoundContent={"No Project Found"}
      onPopupScroll={onScroll}
      onSearch={debounce((search) => setParams({ search, page: 1 }), 300)}
      mode={multiple && "multiple"}
      options={projects}
      onChange={onChange}
      allowClear={true}
      filterOption={false}
      dropdownRender={(menu) => (
        <>
          {menu}
          {loading && (
            <div className="place-center" style={{ padding: "8px 0" }}>
              <Spin />
            </div>
          )}
        </>
      )}
      className={className}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      size={size}
      {...props}
    />
  )
}

export default ProjectSelect
