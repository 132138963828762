import { Col, Row, Divider } from "antd"
import React from "react"

const FunctionTypeDescription = () => {
  return (
    <Row gutter={[0, 8]}>
      <Col span={4}>
        <span style={{ fontWeight: 600, fontSize: 16, color: "var(--black)" }}>Property:</span>
      </Col>
      <Col span={20}>
        The property type is used to define the continuous and queryable states of a device, which
        can represent one or several feature parameters. A property can be read-write or read-only
        for data update and query. When a specific feature parameter is updated, the device can
        update the property accordingly. For example, a light bulb might have properties like power
        state and brightness.
      </Col>
      <Divider />
      <Col span={4}>
        <span style={{ fontWeight: 600, fontSize: 16, color: "var(--black)" }}>Action:</span>
      </Col>
      <Col span={20}>
        The action type is used to run complex tasks. An action command is not intended to change
        the device property but directs the device to return a response. For example, face
        recognition and picture transmission.
      </Col>
      <Divider />
      <Col span={4}>
        <span style={{ fontWeight: 600, fontSize: 16, color: "var(--black)" }}>Event:</span>
      </Col>
      <Col span={20}>
        The event type is used to define live notification reported by a device, which requires
        external sensing and processing. Events work with message subscription or rule engines to
        respond as per the predefined logic. For example, overheating alerts and fault alerts.
      </Col>
    </Row>
  )
}

export default FunctionTypeDescription
