import {
  RightOutlined,
  HomeOutlined,
  LinkOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons"
import { Divider, Image, List } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { getTraceability } from "~/services/apis"

function TraceabilityClientPage() {
  const [selected, setSelected] = useState("overview")
  const { traceabilityId } = useParams()
  const [loading, setLoading] = useState(false)
  const [traceability, setTraceability] = useState(null)

  const fetchTraceability = async () => {
    try {
      setLoading(true)
      const result = await getTraceability(traceabilityId)

      if (result?.traceability) {
        setTraceability(result.traceability)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTraceability()
  }, [traceabilityId])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [selected])

  const overviewData = useMemo(
    () => [
      {
        title: "Mã số",
        value: traceability?.batchId,
      },
      {
        title: "Khối lượng tịnh",
        value: `${traceability?.product?.netWeight} ${traceability?.product?.unit}`,
      },
      {
        title: "Số lượng",
        value: `${traceability?.quantity} ${traceability?.product?.packagingUnit}`,
      },
      {
        title: "Ngày sản xuất",
        value: dayjs(traceability?.manufactureDate).format("DD-MM-YYYY"),
      },
      {
        title: "Ngày hết hạn",
        value: dayjs(traceability?.expiredDate).format("DD-MM-YYYY"),
      },
    ],
    [traceability],
  )

  const enterpriseData = useMemo(
    () => [
      {
        icon: <TeamOutlined />,
        value: traceability?.space?.businessCode,
      },
      {
        icon: <HomeOutlined />,
        value: traceability?.space?.businessAddress,
      },
      {
        icon: <MailOutlined />,
        value: traceability?.space?.businessEmail,
      },
      {
        icon: <PhoneOutlined />,
        value: traceability?.space?.businessPhone,
      },
      {
        icon: <LinkOutlined />,
        value: traceability?.space?.businessWebsite,
      },
    ],
    [traceability],
  )

  const calculateStageDates = (procedureStartDate, stages) => {
    let currentDate = dayjs(procedureStartDate)
    return stages.map((stage) => {
      const stageStartDate = currentDate.clone()
      let stageEndDate = stageStartDate.clone()

      stage.stageTasks.forEach(({ startedAt, task }) => {
        if (dayjs(startedAt).isAfter(stageEndDate)) {
          stageEndDate = dayjs(startedAt)
        }
      })

      currentDate = stageEndDate.add(1, "day")
      return { ...stage, startDate: stageStartDate, endDate: stageEndDate }
    })
  }

  const stagesWithDates = useMemo(() => {
    if (traceability?.procedure?.procedureStages && traceability?.procedure?.startDate) {
      return calculateStageDates(
        traceability.procedure.startDate,
        traceability.procedure.procedureStages,
      )
    }
    return []
  }, [traceability])

  return (
    <div className="relative mx-auto min-h-screen w-full max-w-lg bg-gray-100 shadow-lg">
      {loading && (
        <div className="absolute z-[20] grid h-full w-full place-items-center bg-black/60">
          <LoadingOutlined className="text-[48px] text-primary-color" />
        </div>
      )}
      <div className="relative z-[10]">
        <div className="sticky left-0 right-0 top-0 z-40 flex h-16 flex-row items-center justify-between bg-[#3a2b2a] px-10 shadow">
          <img
            src={traceability?.space?.thumbnailUrl || "/assets/png/logo.png"}
            className="w-[50px] cursor-pointer object-contain"
            onClick={() => window.open("https://demeter.vn")}
          />
          <h1 className="text-[16px] font-medium text-white">Truy xuất nguồn gốc</h1>
        </div>
        {selected === "overview" && (
          <div className="pb-16">
            <div className="p-4">
              <div className="rounded-lg bg-white p-4 shadow">
                <div className="flex items-baseline justify-between">
                  <h2 className="mt-0">{traceability?.product?.name}</h2>
                  <a
                    href={`/traceability/product/${traceability?.product?.id}`}
                    className="text-[12px] font-medium text-primary-color"
                  >
                    Thông tin chi tiết
                  </a>
                </div>
                <List
                  header={null}
                  footer={null}
                  dataSource={overviewData}
                  renderItem={(item) => (
                    <List.Item>
                      <div className="mx-2 flex w-full justify-between">
                        <span className="font-semibold">{item?.title}</span>
                        <span>{item?.value}</span>
                      </div>
                    </List.Item>
                  )}
                />
                <h2 className="mb-0">Hình ảnh</h2>
                <div className="-mx-2 mt-[10px] flex flex-wrap gap-2 pl-[32px] [&_.ant-image-mask]:rounded">
                  <Image.PreviewGroup>
                    {traceability?.product?.imageUrls?.map((image) => (
                      <Image
                        key={image}
                        width={90}
                        height={90}
                        className="rounded object-cover"
                        src={image}
                      />
                    ))}
                  </Image.PreviewGroup>
                </div>

                {traceability?.note && (
                  <div className="mt-4">
                    <h2 className="mb-0">Mô tả</h2>
                    <div className="mt-2">{traceability?.note}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="p-4">
              <div className="rounded-lg bg-white p-4 shadow">
                <h2 className="mt-0">{traceability?.space?.businessName}</h2>
                <List
                  header={null}
                  footer={null}
                  dataSource={enterpriseData}
                  renderItem={(item) => (
                    <List.Item>
                      <div className="mx-2 flex w-full gap-[16px]">
                        <span className="font-semibold">{item?.icon}</span>
                        <span>{item?.value}</span>
                      </div>
                    </List.Item>
                  )}
                />
                {[
                  traceability?.space?.thumbnailUrl,
                  ...(traceability?.space?.imageUrls || []),
                ].filter(Boolean).length > 0 && (
                  <div className="-mx-2 mt-[10px] flex flex-wrap gap-2 pl-[32px] [&_.ant-image-mask]:rounded">
                    <Image.PreviewGroup>
                      {[
                        traceability?.space?.thumbnailUrl,
                        ...(traceability?.space?.imageUrls || []),
                      ]
                        .filter(Boolean)
                        ?.map((image) => (
                          <Image
                            key={image}
                            width={90}
                            height={90}
                            className="rounded object-cover"
                            src={image}
                          />
                        ))}
                    </Image.PreviewGroup>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {selected === "procedure" && (
          <div className="pb-16">
            <div className="p-4">
              <div className="rounded-lg bg-white p-4 shadow">
                <h1 className="mt-0">QUY TRÌNH SẢN XUẤT</h1>

                {stagesWithDates?.map(({ id, stage, stageTasks, startDate, endDate }) => {
                  const isSameDay = dayjs(startDate).isSame(endDate, "day")

                  return (
                    <div key={id} className="">
                      <div className="flex flex-row items-baseline justify-between">
                        <h2 className="text-primary-color">{stage?.name}</h2>

                        <div className="font-medium">
                          {!isSameDay && (
                            <>
                              <span>{startDate.format("DD-MM-YYYY")}</span>
                              <RightOutlined className="px-1" />
                            </>
                          )}
                          <span>{endDate.format("DD-MM-YYYY")}</span>
                        </div>
                      </div>

                      <Divider className="mt-0" />
                      {stageTasks?.map(({ id, task, startedAt, stageTaskSupplies }) => {
                        return (
                          <div key={id}>
                            <div className="my-[12px] flex w-full items-center justify-between text-[15px] font-medium">
                              <span>{task?.name}</span>
                              <span className="text-[12px]">
                                {dayjs(startedAt).format("DD-MM-YYYY")}
                              </span>
                            </div>
                            <div className="pl-[20px] text-[12px]">
                              {stageTaskSupplies?.length > 0 && (
                                <div className="mb-2 font-medium">Vật tư sử dụng</div>
                              )}
                              {stageTaskSupplies?.map(({ id, supply, quantity, unit }) => {
                                return (
                                  <div key={id} className="flex flex-row gap-[16px] pl-[10px]">
                                    <div className="w-[150px] overflow-hidden">
                                      <div className="line-clamp-1">• {supply?.name}</div>
                                    </div>
                                    <div>
                                      {quantity} {unit}
                                    </div>
                                    <a
                                      href={`/traceability/supply/${supply?.id}`}
                                      className="grow cursor-pointer text-end text-[10px] font-medium text-primary-color"
                                    >
                                      Thông tin
                                    </a>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )
                      })}
                      {stage?.imageUrls?.length > 0 && <h3 className="text-primary">Hình ảnh</h3>}
                      <div className="-mx-2 mt-[10px] flex flex-wrap gap-2 pl-[32px] [&_.ant-image-mask]:rounded">
                        <Image.PreviewGroup>
                          {stage?.imageUrls?.map((image) => (
                            <Image
                              key={image}
                              width={90}
                              height={90}
                              src={image}
                              className="rounded object-cover"
                            />
                          ))}
                        </Image.PreviewGroup>
                      </div>
                      <Divider />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
        <div className="h-[64px]" />
        <div className="z-100 fixed bottom-0 left-1/2 flex w-full max-w-lg -translate-x-1/2 transform flex-row items-center justify-around bg-white shadow-lg">
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault()
              setSelected("overview")
            }}
            className={`flex flex-col items-center justify-center whitespace-nowrap rounded-md px-2 py-2 font-semibold capitalize text-gray-400 ${selected === "overview" ? "!text-primary-color" : ""}`}
          >
            <HomeOutlined className="mb-[4px] text-[20px]" />
            <span>Tổng quan</span>
          </a>
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault()
              setSelected("procedure")
            }}
            className={`flex flex-col items-center justify-center whitespace-nowrap rounded-md px-2 py-2 font-semibold capitalize text-gray-400 ${selected === "procedure" ? "!text-primary-color" : ""}`}
          >
            <UnorderedListOutlined className="mb-[4px] text-[20px]" />
            <span>Quy trình</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default TraceabilityClientPage
