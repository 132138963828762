import React, { useState } from "react"
import { Form, Row, Col, Select, Radio, Input, InputNumber, Modal } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"
import { DEVICE_FUNCTION_DATA_TYPE } from "~/constants"
import { parseSafe } from "~/utils"
import { DEVICE_FUNCTION_SCALE } from "~/constants"
import { DataTypeDescription } from "~/components"

const Parameter = ({ form, paramIndex }) => {
  const [showModal, setShowModal] = useState(false)
  const { setFieldsValue, getFieldValue } = form
  const dataType = getFieldValue("parameters")?.[paramIndex]?.dataType
  const type = getFieldValue("type")

  const renderStringFields = () => {
    return (
      <Form.Item name={[paramIndex, "values"]} hidden={true} label={"Values"}>
        <Input />
      </Form.Item>
    )
  }
  const renderValueFields = () => {
    const onFieldChange = (key) => (value) =>
      JSON.stringify({
        ...parseSafe(getFieldValue("parameters")[paramIndex]?.values),
        [key]: value,
      })

    const requiredValidator = ({ key, value }) => {
      if (parseSafe(value)?.[key] !== null && parseSafe(value)?.[key] !== undefined) {
        return Promise.resolve()
      }
      return Promise.reject(new Error("This field is required !"))
    }

    return (
      <>
        <Form.Item
          labelCol={{ span: 24 }}
          style={{ marginBottom: 0 }}
          label={"Value range"}
          required={true}
        >
          <Row gutter={8}>
            <Col span={11}>
              <Form.Item
                getValueProps={(value) => ({
                  value: parseSafe(value)?.minValue,
                })}
                normalize={onFieldChange("minValue")}
                rules={[
                  { required: true, message: "Value range is required" },
                  () => ({
                    validator: (_, value) => requiredValidator({ key: "minValue", value }),
                  }),
                ]}
                name={[paramIndex, "values"]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={2} className={"place-center"} style={{ transform: "translateY(-12px)" }}>
              ~
            </Col>
            <Col span={11}>
              <Form.Item
                getValueProps={(value) => ({
                  value: parseSafe(value)?.maxValue,
                })}
                normalize={onFieldChange("maxValue")}
                rules={[
                  { required: true, message: "Value range is required" },
                  () => ({
                    validator: (_, value) => requiredValidator({ key: "maxValue", value }),
                  }),
                  () => ({
                    validator: (_, value) => {
                      const parsed = parseSafe(value)
                      if (!isNaN(parsed.maxValue) && parsed.maxValue <= parsed.minValue) {
                        return Promise.reject("Max value must be larger than min value !")
                      }

                      return Promise.resolve()
                    },
                  }),
                ]}
                name={[paramIndex, "values"]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          getValueProps={(value) => ({
            value: parseSafe(value)?.pitch,
          })}
          normalize={onFieldChange("pitch")}
          label={"Pitch"}
          rules={[
            { required: true },
            () => ({
              validator: (_, value) => requiredValidator({ key: "pitch", value }),
            }),
          ]}
          name={[paramIndex, "values"]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          getValueProps={(value) => ({
            value: parseSafe(value)?.scale,
          })}
          normalize={onFieldChange("scale")}
          label={"Scale"}
          rules={[
            { required: true },
            () => ({
              validator: (_, value) => requiredValidator({ key: "scale", value }),
            }),
          ]}
          name={[paramIndex, "values"]}
        >
          <Select
            defaultActiveFirstOption={true}
            options={DEVICE_FUNCTION_SCALE.map((item) => ({ label: item, value: item }))}
          />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          getValueProps={(value) => ({
            value: parseSafe(value)?.unit,
          })}
          normalize={onFieldChange("unit")}
          label={"Unit"}
          name={[paramIndex, "values"]}
        >
          <Input />
        </Form.Item>
      </>
    )
  }
  const renderBoolFields = () => {
    return (
      <Form.Item name={[paramIndex, "values"]} hidden={true} label={"Values"}>
        <Input />
      </Form.Item>
    )
  }
  const renderRawFields = () => {
    return (
      <Form.Item hidden={true} label={"Values"} name={[paramIndex, "values"]}>
        <Input />
      </Form.Item>
    )
  }
  const renderDateFields = () => {
    return (
      <Form.Item hidden={true} label={"Values"} name={[paramIndex, "values"]}>
        <Input />
      </Form.Item>
    )
  }
  const renderEnumFields = () => {
    return (
      <Form.Item
        labelCol={{ span: 24 }}
        label={"Enum values"}
        getValueProps={(value) => ({ value: parseSafe(value)?.value })}
        normalize={(value) => JSON.stringify({ value })}
        name={[paramIndex, "values"]}
        rules={[
          { required: true, message: "This field is required !" },
          () => ({
            validator(_, arr) {
              if (parseSafe(arr)?.value?.length > 0) {
                return Promise.resolve()
              }

              return Promise.reject(new Error("Enum must have at least one value!"))
            },
          }),
        ]}
      >
        <Select
          open={false}
          placeholder={"Fill an enum value then press Enter to add...."}
          mode={"tags"}
        />
      </Form.Item>
    )
  }

  const renderFieldByDataType = ({ dataType }) => {
    const handlers = {
      [DEVICE_FUNCTION_DATA_TYPE.String]: renderStringFields,
      [DEVICE_FUNCTION_DATA_TYPE.Bool]: renderBoolFields,
      [DEVICE_FUNCTION_DATA_TYPE.Value]: renderValueFields,
      [DEVICE_FUNCTION_DATA_TYPE.Date]: renderDateFields,
      [DEVICE_FUNCTION_DATA_TYPE.Raw]: renderRawFields,
      [DEVICE_FUNCTION_DATA_TYPE.Enum]: renderEnumFields,
    }

    return (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.parameters?.[paramIndex]?.dataType !==
          curValues?.parameters?.[paramIndex]?.dataType
        }
      >
        {() => handlers?.[dataType]?.()}
      </Form.Item>
    )
  }

  const onChangeDataType = () => {
    const parameters = getFieldValue("parameters")
    parameters[paramIndex] = {
      ...parameters[paramIndex],
      values: JSON.stringify({}), //reset values
    }

    setFieldsValue({ parameters })
  }

  return (<>
    <Modal
      width={800}
      style={{ top: 50 }}
      title={"Data Type Description"}
      open={showModal}
      onCancel={() => {
        form.validateFields()
        setShowModal(false)
      }}
      footer={null}
    >
      <DataTypeDescription />
    </Modal>
    <Form.Item
      label={
        <span>
          Data Type&nbsp;&nbsp;
          <InfoCircleOutlined className="c-p" onClick={() => setShowModal(true)} />
        </span>
      }
      rules={[{ required: true, message: "Data type is required !" }]}
      name={[paramIndex, "dataType"]}
    >
      <Radio.Group
        options={Object.keys(DEVICE_FUNCTION_DATA_TYPE).map((item) => ({
          label: item,
          value: item,
        }))}
        onChange={onChangeDataType}
      />
    </Form.Item>
    {type !== "property" && (
      <>
        <Form.Item
          labelCol={{ span: 24 }}
          label={"Name"}
          name={[paramIndex, "name"]}
          rules={[{ required: true, message: "Name is required !" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label={"Identifier"}
          name={[paramIndex, "key"]}
          rules={[
            { required: true, message: "Identifier is required !" },
            () => ({
              validator(_, value) {
                if (value.match(/^[a-z0-9_]+$/)) {
                  return Promise.resolve()
                }

                return Promise.reject(
                  new Error(
                    "Identifier must only contain lowercase letters, numbers and underscores !",
                  ),
                )
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
      </>
    )}
    {renderFieldByDataType({ dataType })}
  </>);
}

export default Parameter
