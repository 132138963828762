import { Button, Col, Divider, Form, Input, Popconfirm, Row } from "antd"
import React, { useEffect } from "react"
import { ProjectSelect, Upload, GroupSelect, UserSelect } from "~/components"

const CameraForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onConfirmDelete = () => {},
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  const handleSubmit = (values) => {
    onSubmit(values)
  }

  return (
    <>
      <Form
        labelCol={{ span: 6 }}
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={handleSubmit}
      >
        <Row gutter={20}>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Camera Name"}
              name="name"
              rules={[{ required: true, message: "Camera name is required" }]}
            >
              <Input placeholder={"Please input Camera name"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Camera Thumbnail"}
              name="imageUrl"
              rules={[{ required: true, message: "Image is required !" }]}
            >
              <Upload description={"Upload an image of 800 x 800 pixels."} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"HD View Url"}
              name="hdViewUrl"
              rules={[{ required: true, message: "HD View Url required !" }]}
            >
              <Input.TextArea placeholder={"Please input HD View Url"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"SD View Url"}
              name="sdViewUrl"
              rules={[{ required: true, message: "SD View Url required !" }]}
            >
              <Input.TextArea placeholder={"Please input SD View Url"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item label={"Owner"} name="creator">
              <UserSelect
                onChange={(creator) => form.setFieldsValue({ creator, project: null, group: null })}
              />
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prev, cur) =>
              prev.projectId !== cur.projectId || prev.creatorId !== cur.creatorId
            }
          >
            {() => (
              <>
                <Col xs={24} lg={24}>
                  <Form.Item label={"Project"} name="project">
                    <ProjectSelect
                      creatorId={form.getFieldValue("creator")?.value || undefined}
                      excludes={[id]}
                      onChange={(project = null) => form.setFieldsValue({ project, group: null })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item label={"Zone"} name="group">
                    <GroupSelect
                      excludes={[id]}
                      projectId={form.getFieldValue("project")?.value || undefined}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.Item>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="end">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              {id ? "Save" : "Create"}
            </Button>
          </div>
          {id && (
            <div>
              <Popconfirm
                title={"Are you sure to delete this camera ?"}
                onConfirm={onConfirmDelete}
              >
                <Button loading={loading} type={"danger"} style={{ marginLeft: 16 }}>
                  Remove camera
                </Button>
              </Popconfirm>
            </div>
          )}
        </Row>
      </Form>
    </>
  )
}

export default CameraForm
