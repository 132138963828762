import { Button, Col, Divider, message, Row, Table, Tag } from "antd"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSetState } from "react-use"
import { useApi, useSupply } from "~/hooks"
import queryString from "query-string"
import dayjs from "dayjs"
import { useHistory, useLocation } from "react-router-dom"
import { createSupply, removeSupply, updateSupply } from "~/services/apis"

const DEFAULT_PARAMS = {
  search: "",
  page: 1,
  limit: 20,
}

function SuppliesPage() {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const qs = queryString.parse(location.search)
  const [params, setParams] = useSetState(Object.keys(qs)?.length > 0 ? qs : { ...DEFAULT_PARAMS })
  const viewSupplyRef = useRef(null)

  const api = `
    query supplies($params: FetchDto!) {
        supplies(fetch: $params) {
          id
          name
          kind
          imageUrls
          producerName
          brandName
          features
          url
          unit
          available
          supplyStockCards {
            onHand
          }
          description
          createdAt
          updatedAt
        }
      }
  `

  const response = useApi(
    api,
    {
      params: {
        ...params,
        limit: params?.limit && +params.limit,
        page: params?.page && +params.page,
      },
    },
    {
      revalidateOnFocus: true,
    },
  )

  useEffect(
    () => history.replace(`${location.pathname}?${queryString.stringify(params)}`),
    // eslint-disable-next-line
    [params],
  )

  const parsedData = useMemo(() => {
    const { data, error } = response
    const headers = data?.headers

    return {
      error,
      data: data?.data?.supplies,
      meta: (headers && JSON.parse(headers.get("meta"))) || {},
    }
  }, [response])

  const onSupplySubmit = async (values) => {
    try {
      setLoading(true)
      let result

      if (viewSupplyRef.current) {
        result = await updateSupply(viewSupplyRef.current.id, values)
      } else {
        result = await createSupply(values)
      }

      if (result.createSupply) {
        message.success("Tạo vật tư nông nghiệp thành công!")
        viewSupplyRef.current = null
        hideSupplyDrawer()

        response.mutate()
      }

      if (result.updateSupply) {
        message.success("Cập nhật vật tư nông nghiệp thành công!")
        viewSupplyRef.current = null
        hideSupplyDrawer()

        response.mutate()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onSupplyRemove = async () => {
    try {
      const result = await removeSupply(viewSupplyRef.current.id)

      if (result.removeSupply) {
        message.success("Xóa vật tư nông nghiệp thành công!")
        viewSupplyRef.current = null
        hideSupplyDrawer()

        response.mutate()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: "Vật tư nông nghiệp",
      key: "name",
      dataIndex: "name",
      width: 200,
      className: "c-p",
      render: (_, record) => {
        return <span className="line-clamp-1 font-semibold">{record?.name}</span>
      },
    },
    {
      title: "Tồn kho",
      key: "stock",
      dataIndex: "stock",
      className: "c-p",
      width: 150,
      render: (_, record) => {
        return (
          <span>
            {record?.supplyStockCards?.[0]?.onHand
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "--"}
          </span>
        )
      },
    },
    {
      title: "Có thể sử dụng",
      key: "available",
      dataIndex: "available",
      className: "c-p",
      width: 150,
      render: (_, record) => {
        return (
          <span>
            {record?.available?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "--"}
          </span>
        )
      },
    },
    {
      title: "Nhà sản xuất",
      key: "producerName",
      dataIndex: "producerName",
      className: "c-p",
      width: 180,
      render: (value) => {
        return value ? <span className="line-clamp-1">{value}</span> : "-"
      },
    },
    {
      title: "Thương hiệu",
      key: "brandName",
      dataIndex: "brandName",
      className: "c-p",
      width: 180,
      render: (value) => {
        return value ? <span className="line-clamp-1">{value}</span> : "-"
      },
    },
    {
      title: "Đặc điểm",
      key: "features",
      dataIndex: "features",
      className: "c-p",
      width: 200,
      render: (value) => {
        return value ? <span className="line-clamp-2">{value}</span> : "-"
      },
    },
    {
      title: "Ngày tạo",
      key: "createdAt",
      dataIndex: "createdAt",
      className: "c-p",
      width: 180,
      render: (value) => {
        return value ? dayjs(value).format("DD-MM-YYYY") : "-"
      },
    },
  ]

  const {
    show: showSupplyDrawer,
    hide: hideSupplyDrawer,
    SupplyDrawer,
  } = useSupply({
    supply: viewSupplyRef.current,
    loading,
    onSubmit: onSupplySubmit,
    onRemove: onSupplyRemove,
    onClose: () => {
      viewSupplyRef.current = null
    },
  })

  return (
    <div className="max-lg:p-3">
      <div className="text-end">
        <Button type="primary" onClick={showSupplyDrawer}>
          Tạo vật tư nông nghiệp
        </Button>
        <Divider />
      </div>

      <Row>
        <Col span={24}>
          <Table
            loading={response.isValidating}
            rowKey={"id"}
            columns={columns}
            scroll={{
              x: columns.reduce((acc, col) => {
                acc += col.width || 100

                return acc
              }, 300),
              scrollToFirstRowOnChange: true,
            }}
            dataSource={parsedData?.data}
            onRow={(record) => ({
              onClick: () => {
                viewSupplyRef.current = record
                showSupplyDrawer()
              },
            })}
            pagination={{
              total: parsedData?.meta?.totalDocs,
              pageSize: params.limit,
              showSizeChanger: false,
              hideOnSinglePage: true,
              onChange: (page) => setParams({ page }),
              current: Number(params?.page || 1),
            }}
          />
        </Col>
      </Row>
      <SupplyDrawer />
    </div>
  )
}

export default SuppliesPage
