import React, { useState, useCallback } from "react"
import { Drawer } from "antd"
import TraceabilityForm from "~/components/TraceabilityForm"

const useTraceability = ({
  traceability,
  loading = false,
  width = "60%",
  onClose,
  onSubmit,
  onRemove,
} = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const TraceabilityDrawer = useCallback(() => {
    return (
      <Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Truy xuất"}
        open={visible}
        onClose={onCancel}
      >
        <TraceabilityForm
          id={traceability?.id}
          initialValues={{
            ...traceability,
          }}
          onSubmit={onSubmit}
          onRemove={onRemove}
          onCancel={onCancel}
          loading={loading}
        />
      </Drawer>
    )
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    TraceabilityDrawer,
  }
}

export default useTraceability
