import { Button, Col, Divider, message, Row, Table, Tag } from "antd"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSetState } from "react-use"
import { useApi, useStage } from "~/hooks"
import queryString from "query-string"
import dayjs from "dayjs"
import { useHistory, useLocation } from "react-router-dom"
import { createStage, removeStage, updateStage } from "~/services/apis"
import TaskTag from "~/components/TaskTag"

const DEFAULT_PARAMS = {
  search: "",
  page: 1,
  limit: 20,
}

function StagesPage() {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const qs = queryString.parse(location.search)
  const [params, setParams] = useSetState(Object.keys(qs)?.length > 0 ? qs : { ...DEFAULT_PARAMS })
  const viewStageRef = useRef(null)

  const api = `
    query stages($params: FetchDto!) {
        stages(fetch: $params) {
          id
          name
          description
          imageUrls
          createdAt
          updatedAt
          tasks {
            id
            name
            priority
            executionDays
          }
        }
      }
  `

  const response = useApi(
    api,
    {
      params: {
        ...params,
        limit: params?.limit && +params.limit,
        page: params?.page && +params.page,
      },
    },
    {
      revalidateOnFocus: true,
    },
  )

  useEffect(
    () => history.replace(`${location.pathname}?${queryString.stringify(params)}`),
    // eslint-disable-next-line
    [params],
  )

  const parsedData = useMemo(() => {
    const { data, error } = response
    const headers = data?.headers

    return {
      error,
      data: data?.data?.stages,
      meta: (headers && JSON.parse(headers.get("meta"))) || {},
    }
  }, [response])

  const onStageSubmit = async (values) => {
    try {
      setLoading(true)
      let result

      if (viewStageRef.current) {
        result = await updateStage(viewStageRef.current.id, values)
      } else {
        result = await createStage(values)
      }

      if (result.createStage) {
        message.success("Tạo giai đoạn thành công!")
        viewStageRef.current = null
        hideStageDrawer()

        response.mutate()
      }

      if (result.updateStage) {
        message.success("Cập nhật giai đoạn thành công!")
        viewStageRef.current = null
        hideStageDrawer()

        response.mutate()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onStageRemove = async () => {
    try {
      const result = await removeStage(viewStageRef.current.id)

      if (result.removeStage) {
        message.success("Xóa giai đoạn thành công!")
        viewStageRef.current = null
        hideStageDrawer()

        response.mutate()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: "Tên giai đoạn",
      key: "name",
      dataIndex: "name",
      width: 400,
      className: "c-p",
      render: (_, record) => {
        return <div className="line-clamp-1 font-semibold">{record?.name}</div>
      },
    },
    {
      title: "Tổng số ngày thực hiện",
      key: "executionDays",
      dataIndex: "executionDays",
      className: "c-p",
      width: 190,
      align: "center",
      render: (_, record) => {
        const totalExecutionDays = record?.tasks?.reduce((acc, i) => {
          return acc + i?.executionDays
        }, 0)

        return (
          <div className="text-center">
            <b>{totalExecutionDays}</b> ngày
          </div>
        )
      },
    },
    {
      title: "Số lượng công việc",
      key: "taskCount",
      dataIndex: "taskCount",
      className: "c-p",
      width: 180,
      align: "center",
      render: (_, record) => {
        return (
          <span>
            <b>{record?.tasks?.length}</b> công việc
          </span>
        )
      },
    },
    {
      title: "Mô tả",
      key: "description",
      dataIndex: "description",
      className: "c-p",
      width: 300,
      render: (value) => {
        return value ? <span className="line-clamp-2">{value}</span> : "-"
      },
    },
    {
      title: "Ngày tạo",
      key: "createdAt",
      dataIndex: "createdAt",
      className: "c-p",
      render: function render(value) {
        return value ? dayjs(value).format("DD-MM-YYYY") : "-"
      },
    },
  ]

  const {
    show: showStageDrawer,
    hide: hideStageDrawer,
    StageDrawer,
  } = useStage({
    stage: viewStageRef.current,
    loading,
    onSubmit: onStageSubmit,
    onRemove: onStageRemove,
    onClose: () => {
      viewStageRef.current = null
    },
  })

  return (
    <div>
      <div className="text-end">
        <Button type="primary" onClick={showStageDrawer}>
          Tạo giai đoạn
        </Button>
        <Divider />
      </div>

      <Row>
        <Col span={24}>
          <Table
            loading={response.isValidating}
            rowKey={"id"}
            columns={columns}
            scroll={{
              x: columns.reduce((acc, col) => {
                acc += col.width || 100

                return acc
              }, 300),
              scrollToFirstRowOnChange: true,
            }}
            dataSource={parsedData?.data}
            onRow={(record) => ({
              onClick: () => {
                viewStageRef.current = {
                  ...record,
                  tasks: record?.tasks?.map((task) => ({
                    label: (
                      <span>
                        {task?.name}&nbsp;/&nbsp;
                        <TaskTag value={task?.priority} />
                        &nbsp;/&nbsp;<span className="font-semibold">
                          {task?.executionDays}
                        </span>{" "}
                        ngày thực hiện
                      </span>
                    ),
                    value: task?.id,
                  })),
                }
                showStageDrawer()
              },
            })}
            pagination={{
              total: parsedData?.meta?.totalDocs,
              pageSize: params.limit,
              showSizeChanger: false,
              hideOnSinglePage: true,
              onChange: (page) => setParams({ page }),
              current: Number(params?.page || 1),
            }}
          />
        </Col>
      </Row>
      <StageDrawer />
    </div>
  )
}

export default StagesPage
