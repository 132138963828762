import React from "react"
import { ReactComponent as Dashboard } from "~/assets/svg/dashboard.svg"
import { ReactComponent as Users } from "~/assets/svg/users.svg"
import { ReactComponent as Products } from "~/assets/svg/products.svg"
import { ReactComponent as Categories } from "~/assets/svg/categories.svg"
import { ReactComponent as Devices } from "~/assets/svg/devices.svg"
import { ReactComponent as Projects } from "~/assets/svg/projects.svg"
import { ReactComponent as Rules } from "~/assets/svg/rules.svg"
import { ReactComponent as App } from "~/assets/svg/app.svg"
import { ReactComponent as Cloud } from "~/assets/svg/cloud.svg"
import { ReactComponent as Data } from "~/assets/svg/data.svg"
import { ReactComponent as Settings } from "~/assets/svg/settings.svg"
import { ReactComponent as Help } from "~/assets/svg/help.svg"
import { ReactComponent as Avatar } from "~/assets/svg/avatar.svg"
import { ReactComponent as ArrowDown } from "~/assets/svg/arrow-down.svg"
import { ReactComponent as Notify } from "~/assets/svg/notify.svg"
import { ReactComponent as NotifyWithDot } from "~/assets/svg/notify-with-dot.svg"
import { ReactComponent as Logout } from "~/assets/svg/logout.svg"
import { ReactComponent as Filter } from "~/assets/svg/filter.svg"
import { ReactComponent as Back } from "~/assets/svg/back.svg"
import { ReactComponent as Checked } from "~/assets/svg/checked.svg"
import { ReactComponent as Notice } from "~/assets/svg/notice.svg"
import { ReactComponent as VietnamFlag } from "~/assets/svg/Vietnam.svg"
import { ReactComponent as USAFlag } from "~/assets/svg/USA.svg"
import "./index.less"

const svgs = {
  dashboard: Dashboard,
  users: Users,
  products: Products,
  categories: Categories,
  devices: Devices,
  projects: Projects,
  rules: Rules,
  app: App,
  cloud: Cloud,
  data: Data,
  settings: Settings,
  help: Help,
  avatar: Avatar,
  arrowDown: ArrowDown,
  notify: Notify,
  notifyWithDot: NotifyWithDot,
  logout: Logout,
  functions: Settings,
  filter: Filter,
  default: Settings,
  back: Back,
  checked: Checked,
  notice: Notice,
  VietNamFlag: VietnamFlag,
  USAFlag: USAFlag,
}

export default function Svg({
  name,
  className,
  fill,
  width = 18,
  height = 18,
  style = {},
  ...props
}) {
  const SvgComponent = svgs?.[name] || svgs?.default
  return (
    <div className={"svg-root"} style={{ width, height, ...style }}>
      <SvgComponent
        fill={fill || "var(--mid-gray)"}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        className={className}
        {...props}
      />
    </div>
  )
}
