import { useRef, useEffect } from "react"
import socketIOClient from "socket.io-client"
import { API_URL } from "~/constants"

const SOCKET_HOST = API_URL[process.env.REACT_APP_ENV] || API_URL["default"]

const useSocket = ({ namespace = "" }) => {
  const socketRef = useRef(null)

  useEffect(() => {
    socketRef.current = socketIOClient.connect(`${SOCKET_HOST}${namespace}`)

    socketRef.current.on("connect", () => {
      console.log("Socket connected!")
    })
  }, [namespace])

  return socketRef.current
}

export default useSocket
