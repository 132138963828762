import { GraphQLClient } from "graphql-request"
import Cookies from "js-cookie"
import { GRAPHQL_URL } from "~/constants"

export const graphQLClient = new GraphQLClient(GRAPHQL_URL)

export const setToken = (token) => {
  graphQLClient.setHeader("Authorization", `Bearer ${token}`)
}

const token = Cookies.get("token")

if (token) {
  setToken(token)
}
