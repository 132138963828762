/* eslint-disable no-unused-vars */
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Drawer, message, Modal, Spin } from "antd"
import { CameraForm } from "~/components"
import React, { useState } from "react"
import { createCamera, removeCamera, updateCamera } from "~/services/apis"

const CameraContainer = ({ initialValues, onOk, onRemove }) => {
  const id = initialValues?.id
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values) => {
    const { creator, project, group, ...restValues } = values
    const submitValues = {
      ...restValues,
      creatorId: creator?.value,
      projectId: project?.value,
      groupId: group?.value,
    }

    try {
      setLoading(true)
      if (id && id !== "create") {
        await updateCamera(id, submitValues)
        message.success("Update camera success!")
      } else {
        await createCamera(submitValues)
        message.success("Create camera success!")
      }
      onOk && onOk(submitValues)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const confirmDelete = () => {
    Modal.confirm({
      title: "Delete Camera",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => handleRemoveCamera(),
    })
  }

  const handleRemoveCamera = async () => {
    try {
      setLoading(true)
      await removeCamera(id)
      message.success("Delete camera success")
      onRemove && onRemove()
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <CameraForm
        disabled={id && id !== "create" ? true : false}
        id={id !== "create" && id}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onConfirmDelete={confirmDelete}
        loading={loading}
      />
    </div>
  )
}

const useDrawer = ({ camera, width = 850, onOk = () => {}, onClose = () => {} } = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  const CameraDrawer = () => {
    return (
      (<Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Camera"}
        open={visible}
        onClose={() => {
          hide()
          onClose()
        }}
      >
        <CameraContainer
          initialValues={camera}
          onOk={(data) => {
            hide()
            onOk(data)
          }}
        />
      </Drawer>)
    );
  }

  return {
    visible,
    show,
    hide,
    CameraDrawer,
  }
}

CameraContainer.useDrawer = useDrawer

export default CameraContainer
