import React, { createContext, useState } from "react"
import { useHistory } from "react-router-dom"
import Cookies from "js-cookie"
import { isEmpty } from "lodash"
import { setToken } from "~/services/graphqlClient"
import { loginApi, whoAmI, logout } from "~/services/apis"

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const history = useHistory()
  const storagedUser = JSON.parse(Cookies.get("user") || "{}")
  const [user, setUser] = useState(storagedUser)
  const [loading, setLoading] = useState(false)

  const login = async (payload) => {
    setLoading(true)
    try {
      const result = await loginApi(payload)
      const token = result?.login?.accessToken
      if (token) {
        Cookies.set("token", token, { expires: 60 })
        setToken(token)
        const data = await whoAmI()
        Cookies.set("user", JSON.stringify(data?.getProfile))
        setUser(data?.getProfile)

        if (data?.getProfile?.role === "employee") {
          history.push("/employee/dashboard")
        } else {
          history.push("/dashboard")
        }
      }
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !isEmpty(user),
        isAdmin: user.role === "admin",
        user,
        login,
        loading,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
