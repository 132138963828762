import React from "react"
import { Button, Col, Form, Row } from "antd"
import { Link } from "react-router-dom"
import { LanguagesMenu } from "~/components"
import TextField from "~/components/TextField"
import "./styles.less"

export const UserRegisterForm = ({ onSubmit, loading }) => {
  const [form] = Form.useForm()

  const handleSubmit = (values) => {
    onSubmit(values)
  }

  return (
    <Form form={form} name="basic" onFinish={handleSubmit} onFinishFailed={() => {}}>
      <div className="login-container">
        <Form.Item
          name="last_name"
          rules={[
            { required: true, message: "Vui lòng nhập họ" },
            { max: 100, type: "string", message: "Họ quá dài" },
          ]}
        >
          <TextField
            onChange={(e) => form.setFieldsValue({ last_name: e.target.value })}
            label={"Họ"}
            labelStyle={{ textTransform: "uppercase" }}
          />
        </Form.Item>
        <Form.Item
          name="first_name"
          rules={[
            { required: true, message: "Vui lòng nhập tên" },
            { max: 100, type: "string", message: "Tên quá dài" },
          ]}
        >
          <TextField
            onChange={(e) => form.setFieldsValue({ first_name: e.target.value })}
            label={"Tên"}
            labelStyle={{ textTransform: "uppercase" }}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Vui lòng nhập email" },
            { type: "email", message: "Email không đúng định dạnh" },
          ]}
        >
          <TextField
            onChange={(e) => form.setFieldsValue({ email: e.target.value })}
            label={"email"}
            labelStyle={{ textTransform: "uppercase" }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập mật khẩu",
            },
            {
              min: 6,
              message: "Mật khẩu ngắn hơn 6 ký tự",
            },
          ]}
        >
          <TextField
            type="password"
            onChange={(e) => form.setFieldsValue({ password: e.target.value })}
            label={"Mật khẩu"}
            labelStyle={{ textTransform: "uppercase" }}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Vui lòng nhập mật khẩu xác nhận",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error("passwordNotMatchErr"))
              },
            }),
          ]}
        >
          <TextField
            type="password"
            onChange={(e) => form.setFieldsValue({ confirmPassword: e.target.value })}
            label={"Xác nhận mật khẩu"}
            labelStyle={{ textTransform: "uppercase" }}
          />
        </Form.Item>

        <div className="agreement">
          Bằng việc đăng ký, bạn đã đồng ý với{" "}
          <Link className="policy" to="/term-of-service">
            Điều khoản sử dụng{" "}
          </Link>
          và{" "}
          <Link className="policy" to="/private-policy">
            Chính sách bảo mật{" "}
          </Link>{" "}
          của chúng tôi
        </div>
        <Row justify={"space-between"} style={{ marginTop: 8 }}>
          <Col span={12}>
            <Button loading={loading} htmlType={"submit"} className="register-btn" type="primary">
              Tạo tài khoản
            </Button>
          </Col>
          <Col span={8}>
            <LanguagesMenu />
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export default UserRegisterForm
