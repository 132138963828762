import { Button, Col, Divider, Form, Input, Row } from "antd"
import { CategorySelect } from "~/components"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

const CategoryForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onConfirmRemove = () => {},
}) => {
  const history = useHistory()
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  return (
    <div>
      <Form
        layout={"vertical"}
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onSubmit}
      >
        <Row gutter={20}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Name"}
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input placeholder={"Please input name"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label={"Parent category"} name="parentId">
              <CategorySelect excludes={[id]} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              Submit
            </Button>
            <Button
              disabled={loading}
              type={"outline"}
              onClick={history?.goBack}
              style={{ marginLeft: 16 }}
            >
              Back
            </Button>
          </div>
          {id && (
            <div>
              <Button
                loading={loading}
                type={"danger"}
                style={{ marginLeft: 16 }}
                onClick={onConfirmRemove}
              >
                Remove category
              </Button>
            </div>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default CategoryForm
