import React, { useState } from "react"
// import { useRouter } from "next/router"
import { Svg } from ".."
import { Dropdown, Menu, Button } from "antd"
import { CaretDownOutlined } from "@ant-design/icons"
// import { useTranslation } from "~/i18n"
import "./styles.less"

const LanguagesMenu = () => {
  // const { i18n } = useTranslation()
  const [language, setLanguage] = useState("vi")
  // const router = useRouter()

  const onChangeLocale = (locale) => {
    setLanguage(locale)
    // router.push(router.asPath, router.asPath, { locale: locale })
  }

  const locales = {
    vi: { name: "Tiếng Việt", flag: <Svg name="VietNamFlag" /> },
    en: { name: "English", flag: <Svg name="USAFlag" /> },
  }

  const menu = (
    <Menu>
      {Object.keys(locales).map((key) => (
        <Menu.Item key={key} onClick={() => onChangeLocale(key)}>
          <div className="language-menu">
            {locales[key].flag}
            {locales[key].name}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )
  return (
    <Dropdown overlay={menu}>
      <Button className="language-btn">
        {locales[language].flag}
        {locales[language].name}
        <CaretDownOutlined
          className="language-indicator"
          style={{
            position: "absolute",
            right: 12,
            fontSize: 10,
            color: "var(--very-light-gray)",
          }}
        />
      </Button>
    </Dropdown>
  )
}

export default LanguagesMenu
