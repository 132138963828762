import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons"
import { Image, List } from "antd"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { getProduct } from "~/services/apis"

function ProductDetail() {
  const history = useHistory()
  const { productId } = useParams()
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState(null)

  const fetchProduct = async () => {
    try {
      setLoading(true)
      const response = await getProduct(productId)

      if (response?.product) {
        setProduct(response.product)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProduct()
  }, [])

  return (
    <div className="relative mx-auto min-h-screen w-full max-w-lg bg-gray-100 shadow-lg">
      {loading && (
        <div className="absolute z-[20] grid h-full w-full place-items-center bg-black/60">
          <LoadingOutlined className="text-[48px] text-primary-color" />
        </div>
      )}
      <div className="relative z-[10]">
        <div className="sticky left-0 right-0 top-0 z-40 flex h-16 flex-row items-center justify-between bg-[#3a2b2a] pl-5 pr-10 shadow">
          <ArrowLeftOutlined
            className="cursor-pointer text-[20px] text-white"
            onClick={() => {
              if (window.history.length > 1) {
                history.goBack()
              } else {
                window.location.href = "https://demeter.vn"
              }
            }}
          />
          <h1 className="text-[16px] font-medium text-white">Thông tin sản phẩm</h1>
        </div>

        <div className="pb-16">
          <div className="p-4">
            <div className="rounded-lg bg-white p-4 shadow">
              <h2 className="mt-0">{product?.name}</h2>

              <List
                dataSource={[
                  {
                    label: "Giống sản phẩm",
                    value: product?.kind,
                  },
                  {
                    label: "Quy cách đóng gói",
                    value: product?.packagingUnit,
                  },
                  {
                    label: "Khối lượng tịnh",
                    value: `${product?.netWeight} ${product?.unit}`,
                  },
                  {
                    label: "Link mua sản phẩm",
                    value: product?.url,
                    url: true,
                  },
                ]}
                renderItem={(item) => (
                  <List.Item key={item.label}>
                    <List.Item.Meta title={item.label} />
                    {item?.url ? (
                      <a href={item.url}>{item?.value}</a>
                    ) : (
                      <div className="line-clamp-1 max-w-[150px]">
                        {item.value || "Đang cập nhật"}
                      </div>
                    )}
                  </List.Item>
                )}
              />

              <h2 className="mb-0">Hình ảnh</h2>
              <div className="-mx-2 mt-[10px] flex flex-wrap gap-2 pl-[32px] [&_.ant-image-mask]:rounded">
                <Image.PreviewGroup>
                  {product?.imageUrls?.map((image) => (
                    <Image
                      key={image}
                      width={90}
                      height={90}
                      className="object-cover"
                      src={image}
                    />
                  ))}
                </Image.PreviewGroup>

                {product?.imageUrls?.length === 0 && <div>Đang cập nhật</div>}
              </div>

              <div className="mt-[32px]">
                <h2 className="mb-1">Giới thiệu sản phẩm</h2>
                <div
                  className="ck-content relative"
                  dangerouslySetInnerHTML={{ __html: product?.description || "Đang cập nhật" }}
                />
              </div>

              <div className="mt-[32px]">
                <h2 className="mb-1">Hướng dẫn sử dụng</h2>
                <div
                  className="ck-content relative"
                  dangerouslySetInnerHTML={{ __html: product?.userManual || "Đang cập nhật" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetail
