import React, { useState, useCallback } from "react"
import { Drawer, Tabs } from "antd"
import { SupplyForm, SupplyStockCardForm } from "~/components"

const useSupply = ({
  supply,
  loading = false,
  width = "1200",
  onClose,
  onSubmit,
  onRemove,
} = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const SupplyDrawer = useCallback(() => {
    const SupplyFormTabItem = () => (
      <SupplyForm
        id={supply?.id}
        initialValues={{
          ...supply,
        }}
        onSubmit={onSubmit}
        onRemove={onRemove}
        onCancel={onCancel}
        loading={loading}
      />
    )

    return (
      <Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Vật tư nông nghiệp"}
        open={visible}
        onClose={onCancel}
      >
        {supply && (
          <Tabs
            items={[
              {
                label: "Thông tin vật tư",
                key: "info",
                children: <SupplyFormTabItem />,
              },
              {
                label: "Phiếu kho",
                key: "stockCards",
                children: <SupplyStockCardForm supply={supply} />,
              },
            ]}
            size="small"
          />
        )}
        {!supply && <SupplyFormTabItem />}
      </Drawer>
    )
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    SupplyDrawer,
  }
}

export default useSupply
