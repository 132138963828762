import React, { createContext, useEffect, useMemo, useState } from "react"
import Cookies from "js-cookie"
import { parseSafe } from "~/utils"

export const ProjectContext = createContext({})

export const ProjectProvider = ({ children }) => {
  const storagedProject = parseSafe(Cookies.get("project"))
  const [activeProject, setActiveProject] = useState(storagedProject || null)

  useEffect(() => {
    Cookies.set("project", JSON.stringify(activeProject))
  }, [activeProject])

  const contextValue = useMemo(
    () => ({
      activeProject,
      setActiveProject,
    }),
    [activeProject],
  )

  return <ProjectContext.Provider value={contextValue}>{children}</ProjectContext.Provider>
}
