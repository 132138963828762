import React, { useState, useCallback } from "react"
import { Drawer } from "antd"
import { GroupForm } from "~/components"
import * as dayjs from "dayjs"

const useGroup = ({ group, loading = false, width = "60%", onClose, onSubmit, onRemove } = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const GroupDrawer = useCallback(() => {
    return (
      <Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Group"}
        open={visible}
        onClose={onCancel}
      >
        <GroupForm
          id={group?.id}
          initialValues={{
            ...group,
            ...(group?.startDate &&
              group?.endDate && { rangeTime: [dayjs(group?.startDate), dayjs(group?.endDate)] }),
          }}
          onSubmit={onSubmit}
          onRemove={onRemove}
          onCancel={onCancel}
          loading={loading}
        />
      </Drawer>
    )
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    GroupDrawer,
  }
}

export default useGroup
