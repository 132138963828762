import { LoadingOutlined } from "@ant-design/icons"
import { message } from "antd"
import React, { useState, useEffect, useMemo, useRef } from "react"
import { EnterpriseForm } from "~/components"
import { mySpace, updateSpace } from "~/services/apis"

const DEFAULT_PARAMS = {
  search: "",
  page: 1,
  limit: 20,
}

function EnterprisesPage() {
  const [loading, setLoading] = useState(false)
  const [space, setSpace] = useState(null)

  const fetchMySpace = async () => {
    try {
      setLoading(true)
      const response = await mySpace()

      if (response?.mySpace) {
        setSpace(response.mySpace)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchMySpace()
  }, [])

  const onSubmit = async (values) => {
    try {
      const response = await updateSpace(values)
      if (response?.updateSpace) {
        message.success("Cập nhật thành công!")

        fetchMySpace()
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="relative">
      {loading && (
        <div className="absolute z-[20] grid h-full w-full place-items-center bg-white">
          <LoadingOutlined className="text-[48px] text-primary-color" />
        </div>
      )}
      <div className="relative z-[10]">
        <EnterpriseForm initialValues={space} onSubmit={onSubmit} />
      </div>
    </div>
  )
}

export default EnterprisesPage
