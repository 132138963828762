import { Button, Col, Divider, message, Row } from "antd"
import React, { useState } from "react"
import TaskForm from "~/components/TaskForm"
import TaskSelect from "~/components/TaskSelect"
import {
  createProcedureStage,
  createStage,
  createStageTask,
  createTask,
  updateProcedure,
} from "~/services/apis"
import CurrentProcedureStageSelect from "./CurrentProcedureStageSelect"

function AddTaskModal({ procedure, onFinish }) {
  const [newTaskLoading, setNewTaskLoading] = useState(false)
  const [selectedProcedureStage, setSelectedProcedureStage] = useState(null)
  const [selectedTask, setSelectedTask] = useState(null)

  const handleSubmitNewStage = async ({ ...values }) => {
    if (!selectedProcedureStage) {
      message.error("Vui lòng chọn giai đoạn!")
      return
    }

    try {
      setNewTaskLoading(true)

      const response = await createTask({
        ...values,
        procedureStageId: selectedProcedureStage?.value,
        stageId: procedure?.procedureStages?.find((pS) => pS?.id === selectedProcedureStage?.value)
          ?.stageId,
      })

      if (response?.createTask) {
        onFinish?.()
      }

      // if (response?.createTask) {
      //   const newStageTask = await createStageTask({
      //     plannedStartedAt,
      //     taskId: response?.createTask?.id,
      //     procedureStageId: selectedProcedureStage?.value,
      //     stageId: procedure?.procedureStages?.find(
      //       (pS) => pS?.id === selectedProcedureStage?.value,
      //     )?.stageId,
      //   })

      //   if (newStageTask) {
      //     onFinish?.()
      //   }
      // }
    } catch (error) {
      console.log(error)
    } finally {
      setNewTaskLoading(false)
    }
  }

  const handleAddExistingStage = async () => {
    if (!selectedProcedureStage) {
      message.error("Vui lòng chọn giai đoạn!")
      return
    }

    if (!selectedTask) {
      message.error("Vui lòng chọn mẫu công việc!")
      return
    }

    try {
      setNewTaskLoading(true)
      const response = await createStageTask({
        procedureStageId: selectedProcedureStage?.value,
        taskId: selectedTask?.value,
        stageId: procedure?.procedureStages?.find((pS) => pS?.id === selectedProcedureStage?.value)
          ?.stageId,
      })

      if (response?.createStageTask) {
        onFinish?.()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setNewTaskLoading(false)
    }
  }

  return (
    <div className="lg:mx-5lg:mt-5">
      <div className="mb-2 text-[16px] font-medium">
        Chọn giai đoạn <span className="text-red-500">*</span>
      </div>
      <CurrentProcedureStageSelect
        procedureStages={procedure?.procedureStages?.map((pS) => ({
          label: pS?.stage?.name,
          value: pS?.id,
        }))}
        size="large"
        className={"w-full"}
        value={selectedProcedureStage}
        onChange={setSelectedProcedureStage}
      />

      <Divider />
      {/* 
      <div className="mb-2 text-[16px] font-medium">Chọn mẫu công việc</div>
      <Row gutter={10}>
        <Col span={18}>
          <TaskSelect
            size="large"
            className={"w-full"}
            value={selectedTask}
            onChange={setSelectedTask}
          />
        </Col>
        <Col span={6}>
          <Button
            loading={newTaskLoading}
            onClick={() => handleAddExistingStage()}
            block
            size="large"
            type="primary"
          >
            Thêm
          </Button>
        </Col>
      </Row>

      <div className="my-5 text-gray-500">Hoặc</div>
      <div className="mb-2 text-[16px] font-medium">Thêm công việc mới</div> */}

      <TaskForm loading={newTaskLoading} onSubmit={handleSubmitNewStage} />
    </div>
  )
}

export default AddTaskModal
