import React, { useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button, Popconfirm, InputNumber, Select, Tag } from "antd"
import { isEmpty, isEqual } from "lodash"
import { Upload } from ".."
import { useAuth } from "~/hooks"

const StageForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const { user } = useAuth()
  const [form] = Form.useForm()
  // const watchTasks = Form.useWatch("tasks", form)

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = (values) => {
    const { tasks, ...rest } = values

    onSubmit({
      ...rest,
      taskIds: tasks?.map((task) => task?.value)?.filter(Boolean),
    })
  }

  return (
    <div>
      <Form
        colon={false}
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Tên giai đoạn"}
              name="name"
              rules={[{ required: true, message: "Tên giai đoạn là bắt buộc!" }]}
            >
              <Input placeholder={"Nhập tên giai đoạn"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Mô tả"} name="description">
              <Input.TextArea placeholder={"Nhập mô tả"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Hình ảnh mô tả"} name="imageUrls">
              <Upload multiple />
            </Form.Item>
          </Col>

          {/* <Col xs={24} lg={24}>
            <Form.Item label={"Công việc"}>
              <Form.Item name={"tasks"} hidden />
              <div>
                {watchTasks?.length > 0 && (
                  <>
                    {watchTasks?.map((task, index) => (
                      <div
                        key={task}
                        className="mb-[16px] flex w-full items-center justify-center gap-[16px]"
                      >
                        <TaskSelect
                          excludes={watchTasks?.map((task) => task?.value)}
                          value={task}
                          onChange={(task) => {
                            const newTasks = [...watchTasks]
                            newTasks[index] = task
                            form.setFieldValue(`tasks`, newTasks)
                          }}
                          className={"mb-[16px]"}
                          allowClear={false}
                        />
                        <MinusCircleOutlined
                          className="-translate-y-[8px] cursor-pointer"
                          onClick={() => {
                            const newTasks = [...watchTasks]
                            newTasks.splice(index, 1)
                            form.setFieldValue(`tasks`, newTasks)
                          }}
                        />
                      </div>
                    ))}
                  </>
                )}
                <Button
                  type="primary"
                  onClick={() => {
                    if (last(watchTasks) || !watchTasks || watchTasks?.length === 0) {
                      form.setFieldsValue({ tasks: [...(watchTasks || []), null] })
                    }
                  }}
                >
                  Thêm công việc
                </Button>
              </div>
            </Form.Item>
          </Col> */}
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              Lưu
            </Button>
            <Button
              onClick={onCancel}
              disabled={loading}
              type={"outline"}
              style={{ marginLeft: 16 }}
            >
              Hủy
            </Button>
          </div>
          {id && user?.role !== "employee" && (
            <div>
              <Popconfirm title={"Bạn có chắc chắn muốn xóa giai đoạn này ?"} onConfirm={onRemove}>
                <Button loading={loading} danger style={{ marginLeft: 16 }}>
                  Xóa giai đoạn
                </Button>
              </Popconfirm>
            </div>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default StageForm
