import React, { useState, useCallback } from "react"
import { Modal } from "antd"
import { ProcedureStageForm } from "~/components"

const useProcedureStage = ({
  procedureStage,
  loading = false,
  width = "60%",
  onClose,
  onSubmit,
  onRemove,
} = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => {
    setVisible(true)
    document.body.style.overflow = "hidden"
  }
  const hide = () => {
    setVisible(false)
    document.body.style.overflow = "unset"
  }
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const ProcedureStageDrawer = useCallback(() => {
    return (
      <Modal
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Giai đoạn"}
        open={visible}
        centered
        footer={null}
        maskClosable
        onCancel={onCancel}
      >
        <ProcedureStageForm
          id={procedureStage?.id}
          initialValues={{
            ...procedureStage,
          }}
          onSubmit={onSubmit}
          onRemove={onRemove}
          onCancel={onCancel}
          loading={loading}
        />
      </Modal>
    )
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    ProcedureStageDrawer,
  }
}

export default useProcedureStage
